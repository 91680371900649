import {Button, CircularProgress, Stack, Typography} from '@mui/material'
import React, {useState, useEffect, useContext} from 'react'
import { ReportTable } from './ReportTable'
import {customFlowApi} from "../../../consumer/customFlowApi";
import Grid from "@mui/material/Grid";
import Calendar from "../../../common/Calendar";
import "react-datepicker/dist/react-datepicker.css";
import {queryDate} from "../../../common/utils/utils";
import {HomeContext} from "../../context/HomeContext";
import {HistoryModal} from "./HistoryModal";
import {Box} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

function initializeStartDate() {
  const date = new Date()
  date.setDate(1)
  return date
}

function formatNumberWithCommas(number) {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export const ReportsView = () => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = React.useState(null);
  const {alert, setAlert, actualAgent} = useContext(HomeContext);
  const [loading, setLoading] = useState(false);
  const [rangeSelected, setRangeSelected] = useState();
  const [startDate, setStartDate] = useState(initializeStartDate);
  const [endDate, setEndDate] = useState(new Date());
  
  const [data, setData] = useState([]);
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const [contactSelected, setContactSelected] = useState(null);
  const [loadingExport, setLoadingExport ] = useState(false);
  
  const dateRange = (date) => {
    const [start, end] = date;
    setStartDate(start)
    setEndDate(end)
    if(start && end){
      const query = queryDate(start, end);
      setRangeSelected(query);
    }   
  }

  const getClients = (setInfo) => {
    (async () => {
      const response = await customFlowApi.getUserDataPag(rangeSelected, paginationModel.page+1);
      if(response.status === 200){
        /* const resp = response.data.data
        setInfo(resp.reverse()) */
        setInfo(response.data.data)
        setRowCount(response.data.totalRecords);
      }else{
        setAlert({...alert,
          title: `Hubo un problema con la solicitud status: ${response.status}`,
          open: true,
          variant: 'error'
        });
      }
    })();
  }
  const exportClients = () => {
    (async () => {
      setLoadingExport(true);
      const query = `${rangeSelected.replace("startDate", "fechaInicio").replace("endDate","fechaFin")}&waba=${actualAgent.agent.waba}`
      const response = await customFlowApi.getConversationCsv(query);
      const output = response.data.split('\n').map(line => {
        return line.replace(/"\d+"/g, match => {
          return `"${formatNumberWithCommas(match.slice(1, -1))}"`;
        });
      }).join('\n');
      const blob = new Blob(["\uFEFF"+output], { type: 'text/csv;charset=UTF-8' });
      // Crea un enlace para descargar el archivo CSV
      const url = window.URL.createObjectURL(blob);
      // Crea un elemento <a> invisible y lo simula para descargar el archivo CSV
      const a = document.createElement('a');
      a.href = url;
      a.download = 'conversaciones.csv';
      document.body.appendChild(a);
      a.click();
      // Libera el recurso del enlace
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setLoadingExport(false);
    })()
  }
  useEffect(()=>{
    (async () => {
      if(rangeSelected){
        setLoading(true);
        getClients(setData);
        setLoading(false);
      }
    })();
  },[rangeSelected, paginationModel.page]);

  
  useEffect(() => {
    dateRange([startDate, endDate]);
  }, []);

  return (
       <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography textAlign='center' variant="h3" sx={{
            color: "#2596BE", px: 1.5,
            borderRadius: 4,
          }} >
            Reporte de Clientes
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack direction='row' justifyContent="space-between">
            <Box mt={1}>
              <Calendar
                  placeholderText='Seleccionar rango'
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  selected={startDate}
                  onChange={dateRange}/>
            </Box>
            <Button
                onClick={() => exportClients()}
                size='small'
                variant="outlined"
                endIcon={loadingExport ? <CircularProgress size={15} /> : <DownloadIcon/> }>Descargar</Button>
            <HistoryModal setOpen={setIsOpenHistory} open={isOpenHistory} contact={contactSelected}/>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <ReportTable report={data} rowCount={rowCount} paginationModel={paginationModel} setPaginationModel={setPaginationModel} loading={loading} onClickHistory={(contact) => {
            setContactSelected(contact);
            setIsOpenHistory(true);
          }}/>
        </Grid>
      </Grid>
     
  )
}
