import * as React from 'react';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import consumer from "../../../consumer/authModuleAPI";
import {useContext, useState} from 'react';
import {HomeContext} from "../../context/HomeContext";

export default function UserCreate({ state, setState, refresh}) {
    const [password, setPassword] = useState(undefined);
    const {alert, setAlert} = useContext(HomeContext);
    const [username, setUsername] = useState();
    const [role, setRole] = useState(40);

    const gateDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yy = today.getFullYear();
        return `${mm}/${dd}/${yy}`;
    }

    return (
        <Dialog open={state} >
            <DialogTitle>
                <Typography variant="body1" color={"#2596BE"} textAlign={'center'} fontSize={25} >
                    Registra un nuevo usuario
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    required
                    fullWidth
                    id="username"
                    label="Correo"
                    name="username"
                    value={username}
                    onChange={(e)=>setUsername(e.target.value)}
                    sx={{ my: 1.5 }}
                />
                <TextField
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    value={password}
                    autoComplete="new-password"
                    sx={{ my: 1.5 }}
                    onChange={(e)=>setPassword(e.target.value)}
                />
                <Typography variant="body1" color={"#2596BE"} textAlign={'left'} fontSize={16} >
                    Selecciona el tipo de usuario
                </Typography>
                <Select
                    required
                    labelId={`role`}
                    id={`role`}
                    value={role}
                    label="Role"
                    fullWidth
                    onChange={(e)=>setRole(e.target.value)}
                    >
                    <MenuItem value={20}>Administrador</MenuItem>
                    <MenuItem value={30}>Gestor de contenido</MenuItem>
                    <MenuItem value={40}>Analista de contenido</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setUsername("")
                    setPassword("")
                    setRole(40)
                    setState(false)
                }}>Cancelar</Button>
                <Button onClick={() => {
                    let newRole = ""
                    switch(role){
                        case 20:
                            newRole = "Administrador"
                            break;
                        case 30:
                            newRole = "Gestor de contenido"
                            break;
                        case 40:
                            newRole = "Analista de contenido"
                            break;
                        default:
                            newRole = "Sin definir"
                            break;
                    }

                    consumer.createUserFromAdmin({
                        username,
                        password,
                        role: newRole,
                        lastIngress: gateDate()
                    }, (res) => {
                        if (res) {
                            setUsername("")
                            setPassword("")
                            setRole(40)
                            setAlert({...alert, title: 'Creado exitosamente', open: true, variant: 'success'})
                            setState(false);
                            refresh();
                        } else {
                            setAlert({...alert, title: 'Algo pasó', open: true, variant: 'error'})
                        }
                    })
                }}>Registrar</Button>
            </DialogActions>
        </Dialog>

    );
}