import { Navigate, Route, Routes } from "react-router-dom"
import { HomePage } from "../pages/HomePage"
import {
  DashboardView,
  NewAgentView,
  ReportsView,
  HistoryView,
  SettingsView,
  AccessView,
  ProfileView,
  TestChatView,
} from "../views"
import { HomeProvider } from "../context/HomeProvider"
import { AdminRoutes, EditorRoutes } from "../routes"


export const HomeRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={
          <HomeProvider>
            <HomePage>
              <DashboardView/>
            </HomePage>
          </HomeProvider>} />

        <Route path="/agente" element={
          <HomeProvider>
            <EditorRoutes>
              <HomePage>
                <NewAgentView/>
              </HomePage>
            </EditorRoutes>
          </HomeProvider>} />

        <Route path="/reportes" element={
          <HomeProvider>
            <HomePage>
              <ReportsView/>
            </HomePage>
          </HomeProvider>} />

        <Route path="/configuracion" element={
          <HomeProvider>
            <AdminRoutes>
              <HomePage>
                <SettingsView/>
              </HomePage> 
            </AdminRoutes>
          </HomeProvider>} />

        <Route path="/acceso" element={
          <HomeProvider>
            <AdminRoutes>
              <HomePage>
                <AccessView/>
              </HomePage>
            </AdminRoutes>
          </HomeProvider>} />

        <Route path="/pruebas" element={
          <HomeProvider>
            <AdminRoutes>
              <HomePage>
                <TestChatView/>
              </HomePage>
            </AdminRoutes>
          </HomeProvider>} />

        <Route path="/perfil" element={
          <HomeProvider>
            <HomePage>
              <ProfileView/>
            </HomePage>
          </HomeProvider>} />

      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  )
}
