import { useState } from "react"
import { AuthContext } from "./AuthContext"

const initUser = () => {
    const user = localStorage.getItem('user')
    if(user!==undefined){
        return JSON.parse(user)
    }else{
        return null;
    }
}

export const AuthProvider = ({ children }) => {
    
    const [actualUser, setRealActualUser] = useState(initUser())

    const setActualUser = (newUser) => {
        setRealActualUser(newUser)
        localStorage.setItem('user', JSON.stringify(newUser))
    }

  return (
    <AuthContext.Provider value={{actualUser, setActualUser}}>
        {children}
    </AuthContext.Provider>
  )
}
