import * as React from 'react';
import { Box, List ,ListItem ,ListItemAvatar ,IconButton ,ListItemText, Typography, Grid } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AccountCircle from '@mui/icons-material/AccountCircle';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import consumer from "../../../consumer/authModuleAPI";
import { Role } from './Role';
import { AuthContext } from '../../../context/AuthContext';
import { useContext,  useState } from 'react';
import {HomeContext} from "../../context/HomeContext";
import DeleteIcon from '@mui/icons-material/Delete';

export default function UserList({ users, selectUser,  showModal, refresh, setOpenDelete }) {
    
    const { actualUser } = useContext(AuthContext);
    const { setAlert } = useContext(HomeContext);

    const errorAlertData = {
        title: 'Hubo un problema inesperado',
        open: true,
        variant: 'error'
    };
    const useStyle = {
        button: {
            "&:hover": {
                backgroundColor: "#ffffff !important",
                boxShadow: "none !important",
            },
            "&:active": {
                boxShadow: "none !important",
                backgroundColor: "#ffffff !important",
            },
        },
    };
    return (
        <Box
            sx={{ width: '100%', height: 400, overflow:'auto' }} >
                <List sx={{ width: '100%', bgcolor: 'background.paper'}} >
                    {users.map((user) => (
                        <ListItem key={user._id} 
                            sx={{border:1, borderColor:'#dddddd', display:'flex', justifyContent:'space-between'}}                            
                        >
                        <Box display='flex' sx={{flexDirection: { xs: 'column', md: 'row' }, alignItems:{xs: 'center'}}}>
                        {
                            (user.isSuspended) ?
                                <IconButton edge="start" aria-label="unlock" sx={{ ...useStyle.button, mx: 1, color: '#fb6d6d' }}
                                    onClick={ () => {
                                        consumer.updateUser({
                                            ...user,
                                            isSuspended: false,
                                        }, (res) => {
                                            if (res) {
                                                setAlert({
                                                    title: 'La cuenta ha sido habilitada correctamente',
                                                    open: true,
                                                    variant: 'success'
                                                });
                                                refresh()
                                            }else{
                                                setAlert(errorAlertData);
                                            }
                                        })
                                    }
                                }>
                                    <BlurOffIcon/>
                                    <Typography variant="h6" fontSize='small'>
                                        Desactivado
                                    </Typography>
                                </IconButton>
                            :
                                <IconButton edge="start" aria-label="block" sx={{ ...useStyle.button, mx: 1, color: '#0cbc5c' }}
                                    onClick={ () => {
                                        if(user.role!=="Propietario"){
                                            consumer.updateUser({
                                                ...user,
                                                isSuspended: true,
                                            }, (res) => {
                                                if (res) {
                                                    setAlert({
                                                        title: 'La cuenta ha sido desactivada correctamente',
                                                        open: true,
                                                        variant: 'success'
                                                    });
                                                    refresh()
                                                }else{

                                                    setAlert(errorAlertData);
                                                }
                                            })
                                        }
                                    }
                                }>
                                    <BlurOnIcon/>
                                    <Typography variant="h6" fontSize='small'>
                                        Activo
                                    </Typography>
                                </IconButton>
                        }
                      
                                <ListItemAvatar>
                                    <AccountCircle fontSize='large' />
                                </ListItemAvatar>
                         
                                <ListItemText
                                    primary={user.username}
                                    secondary={`${user.name || ""} - Ingreso: ${user.lastIngress}`}
                                /> 
                                                    
                        </Box>
                                <Box display='flex' sx={{flexDirection: { xs: 'column', md: 'row' },}}>
                                    <Role user={user}/>
                                    <Box>
                                        {
                                            //No mostrar para el propietario a menos que el usuario actual sea el propietario
                                            (user.role!=="Propietario" || user._id===actualUser._id) && 
                                            <IconButton edge="end" aria-label="delete" sx={{ ...useStyle.button, mx: 1, color: '#6dc8c2' }}
                                                onClick={ () => {
                                                    consumer.getUser(user._id, (res) => {
                                                        if (res.data) {
                                                            selectUser(res.data)
                                        
                                                            showModal(true)
                                                        } else {
                                                            setAlert(errorAlertData);
                                                        }
                                                    } )
                                                }
                                            }>
                                                <BorderColorIcon />
                                            </IconButton>
                                        }
                                        {
                                            (user.role!=="Propietario" && user._id!==actualUser._id) &&

                                            <IconButton aria-label="delete" edge="end" sx={{ ...useStyle.button, mx: 1, color: '#fb6d6d' }}
                                                onClick={()=>{
                                                    selectUser(user)
                                                    setOpenDelete(true)
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }

                                    </Box>
                                </Box>
                        </ListItem>
                    )
                    )}
                </List>
        </Box>
    );
}