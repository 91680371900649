import { styled } from '@mui/material/styles';
export const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundPosition: 'center 40%',
    backgroundRepeat: 'no-repeat',
    maxWidth: '100%',
    height: 'auto',
    padding: 0, margin: 0
});
