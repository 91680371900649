import { useContext } from "react"
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export const EditorRoutes = ({ children }) => {

  const { actualUser:{role} } = useContext(AuthContext);

  return (role==="Propietario" || role==="Administrador" || role==="Gestor de contenido")
        ? children
        : <Navigate to="/"/>
}