import * as React from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import consumer from "../../consumer/authModuleAPI";
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import {HomeContext} from "../context/HomeContext";

export default function UpdatePassword({ user, state, setState, setDisable }) {
    const [newPassword, setNewPassword] = React.useState(undefined)
    const [commitPassword, setCommitPassword] = React.useState(undefined)
    const { actualUser, setActualUser } = useContext(AuthContext);
    const { setAlert } = useContext(HomeContext);
    const errorAlert = {
        title: 'Hubo un problema inesperado',
        open: true,
        variant: 'error'
    };
    const submit = () => {
        if(newPassword.length > 8){
            if(newPassword === commitPassword){
                consumer.updateUserFromAdmin({
                    ...user,
                    password: newPassword,
                    passwordUpdated: true,
                }, (res) => {
                    if (res) {
                        setActualUser({
                            ...actualUser,
                            password: newPassword,
                        })
                        setNewPassword("")
                        setCommitPassword("")
                        setState(false);
                        setAlert({
                            title: 'La contraseña fue actualizada exitosamente',
                            open: true,
                            variant: 'success'
                        });
                    } else {
                        setAlert(errorAlert);
                    }
                })
            }else{
                setAlert({...errorAlert, title: "La contraseñas no coinciden"});
            }
        }else{
            setAlert({...errorAlert, title: "La contraseña debe ser mayor a 8 digitos"});
        }
    }

    return (
        <Dialog open={state} >
            <DialogTitle>Cambiar contraseña</DialogTitle>
            <DialogContent sx={{}}>
                <TextField
                    disabled={setDisable}
                    fullWidth
                    id="username"
                    label="Correo"
                    name="username"
                    value={user.username}
                    sx={{ my: 1.5 }}
                />
                <TextField
                    required
                    fullWidth
                    name="password"
                    label="Nueva contraseña"
                    type="password"
                    id="password"
                    value={newPassword}
                    autoComplete="new-password"
                    sx={{ my: 1.5 }}
                    onChange={(e)=>setNewPassword(e.target.value)}
                />
                <TextField
                    required
                    fullWidth
                    name="commitPassword"
                    label="Confirmar contraseña"
                    type="password"
                    id="commitPassword"
                    value={commitPassword}
                    sx={{ my: 1.5 }}
                    onChange={(e)=>setCommitPassword(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setNewPassword("")
                    setCommitPassword("")
                    setState(false)
                }}>Cancelar</Button>
                <Button onClick={submit}>
                    Cambiar
                </Button>
            </DialogActions>
        </Dialog>

    );
}