import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Box, Typography} from "@mui/material";
import {ImageBackdrop, ImageButton, ImageSrc, Image, ImageMarked} from "../../../common/ImageButton";
export const useScript = (url, name) => {

    const [lib, setLib] = useState({})

    useEffect(() => {
        const script = document.createElement('script')

        script.src = url
        script.async = true
        script.onload = () => setLib({ [name]: window[name] })

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [url])

    return lib

}

export const TestChatView = () => {

    const abrirVentanaEmergente = () => {
        // Abre una ventana emergente
        const ventanaEmergente = window.open('', 'MiVentanaEmergente', 'width=500, height=600');
        // Puedes escribir contenido HTML en la ventana emergente
        ventanaEmergente.document.write(`<html><head><title>Ventana Emergente</title>
<script src="https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js"></script>
<style>
  df-messenger {
    z-index: 999;
    position: fixed;
    bottom: 16px;
    right: 16px;
  }
</style></head><body><df-messenger
  location="us-central1"
  project-id="abraham-chatbot"
  agent-id="c58027f7-4940-429d-ac79-a623c76bb437"
  language-code="es">
  <df-messenger-chat-bubble
   chat-title="Abraham Chatbot">
  </df-messenger-chat-bubble>
</df-messenger></body></html>`);

        // Puedes añadir más contenido o interactuar con la ventana emergente según tus necesidades.
    }
    return (<>
        <Grid container>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%'}}>
                <ImageButton
                    focusRipple
                    onClick={abrirVentanaEmergente}
                    style={{
                        width: '100%',
                        height: '95vh'
                    }}
                >
                    <ImageSrc style={{ backgroundImage: `url(/img/banner_test.png)` }} />
                    <ImageBackdrop className="MuiImageBackdrop-root" />
                    <Image>
                        <Typography
                            component="span"
                            variant="subtitle1"
                            color="inherit"
                            fontWeight={'bold'}
                            sx={{
                                position: 'relative',
                                p: 4,
                                pt: 2,
                                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                            }}
                        >
                            Probar Abrahan Chatbot
                            <ImageMarked className="MuiImageMarked-root" />
                        </Typography>
                    </Image>
                </ImageButton>
            </Box>
        </Grid>

    </>)
}