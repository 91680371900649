import {Button, Divider, IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from '@mui/icons-material/Sync';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import React from "react";

const ButtonModal = ({ buttonText, modalTitle, children, onClick, open, setOpen, props, onCancel, refresh, csv}) => {
    return (
        <Grid item xs={12} md={12} display={'flex'} justifyContent={'end'}>
            {csv}
            <IconButton  color="primary" onClick={() => refresh()}>
                <SyncIcon/>
            </IconButton>
            <Button
                onClick={() => setOpen(!open) }
                size='small'
                variant="outlined"
                endIcon={<AddIcon/>}>{buttonText}</Button>


            <Dialog open={open} onClose={() => setOpen(!open)} maxWidth={'md'} {...props}>
                <DialogTitle textAlign={'center'}>{modalTitle}</DialogTitle>
                <Divider />
                <DialogContent >
                    {children}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <Button onClick={onClick}>Enviar</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default  ButtonModal