import { useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import { Navigate } from "react-router-dom";

export const PrivateRoutes = ({ children }) => {

  const { actualUser } = useContext(AuthContext);

  return (!!actualUser)
        ? children
        : <Navigate to="/login"/>
}
