import { List, ListItem, ListItemAvatar, ListItemText, Skeleton} from "@mui/material";

import SmartToyIcon from "@mui/icons-material/SmartToy";
import React, {useEffect, useState} from "react";

const CustomList = ({ actions, renderText,  items, isLoading}) => {

    return (
        <>
            {isLoading ? (
                <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </>
            ): (
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {items.length > 0 ? (items.map((item, index) => (
                        <ListItem key={`intent-key-${index}`}
                                  sx={{border:1, borderColor:'#dddddd'}}
                                  secondaryAction={ actions(item) }>
                            <ListItemAvatar>
                                <SmartToyIcon fontSize='medium' />
                            </ListItemAvatar>
                            <ListItemText
                                primary={renderText(item)}
                            />
                        </ListItem>
                    ))) : <ListItem>No hay datos para mostrar</ListItem>}
                </List>
            )}
        </>
    )
}

export default CustomList