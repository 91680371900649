import {request} from "../common/utils/utils";

const baseUrl =  process.env.REACT_APP_BACKEND_WHATSAPP
const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTI5ZGE1ZWY3NTU5NWM1NjFmMjU1M2YiLCJpYXQiOjE2OTcyNDE2OTR9.Eht39LPUpQ38R6yynvjqYUflsiydkGnij__l-0t3KKg'

const baseOptions = {
    mode: 'cors', // no-cors, *cors, same-origin
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
}
const bodyRequest = async (url, body, method) => {
    const response = await fetch(url, {
        ...baseOptions,
        method,
        body: JSON.stringify(body)
    });
    return await response.json();
}

export const customFlowApi = {

    createIntent: async (body) => {
        return await bodyRequest(`${baseUrl}/intenciones`, body, 'POST')
    },
    getIntents: async (number) => {
        return await request(`${baseUrl}/intenciones/${number}`, 'GET', token)
    },
    getPageIntents: async (number, page) => {
        return await request(`${baseUrl}/intencionespag/${number}?page=${page}&limit=10`, 'GET', token)
    },
    getUserDataPag: async (query, page) => {
        return await request(`${baseUrl}/aveasdata/responsespag?${query}&page=${page}&limit=20`, 'GET', token)
    },
    updateIntent: async (id, body) => {
        return await bodyRequest(`${baseUrl}/intenciones/${id}`, body, 'PUT')
    },
    deleteIntent: async (id) => {
        return await request(`${baseUrl}/intenciones/${id}`, 'DELETE', token)
    },
    // ChatBots
    createChatBot: async (body) => {
        return await bodyRequest(`${baseUrl}/chatbot`, body, 'POST')
    },
    getChatBots: async () => {
        return await request(`${baseUrl}/chatbot`, 'GET', token)
    },
    updateChatBot: async (id, body) => {
        return await bodyRequest(`${baseUrl}/chatbot/${id}`, body, 'PUT')
    },
    deleteChatBot: async (id) => {
        return await request(`${baseUrl}/chatbot/${id}`, 'DELETE', token)
    },
    getAveasData: async () => {
        return await request(`${baseUrl}/aveasdata/count`, 'GET', token)
    },
    getUserData: async (query) => {
        return await request(`${baseUrl}/aveasdata/responses?${query}`, 'GET', token)
    },
    getChats: async (phone) => {
        return await request(`${baseUrl}/conversaciones/${phone}`, 'GET', token);
    },
    getRangeChats: async (phone, query) => {
        return await request(`${baseUrl}/conversaciones/${phone}/rango?${query}`, 'GET', token);
    },
    getConversationCsv: async (query) => {
        const response = await fetch( `${baseUrl}/exportar-conversaciones?${query}`, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return {
            status: response.status,
            data: await response.text()
        }

    },
    createUser: async (body) => {
        return await bodyRequest(`${baseUrl}/users`, body, 'POST')
    }, 
    getAllsUser: async () => {
        return await request(`${baseUrl}/users`, 'GET', token);
    },
    updateUser: async (id, body) => {
        return await bodyRequest(`${baseUrl}/users/${id}`, body, 'PUT')
    },
    deleteUser: async (id) => {
        return await request(`${baseUrl}/users/${id}`, 'DELETE', token)
    }


}