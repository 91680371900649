import {IconButton} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

const Actions = ({ item, editCallback, deleteCallBack }) => {
    return (
        <>
            <IconButton edge="end" aria-label="edit" sx={{ mx: 1, color: '#6dc8c2' }}
                        onClick={()=>{
                            editCallback(item)
                        }}
            >
                <BorderColorIcon/>
            </IconButton>
            <IconButton edge="end" aria-label="delete" sx={{ mx: 1, color: '#fb6d6d' }}
                        onClick={ () => {
                            deleteCallBack(item)
                        }}>
                <DeleteIcon/>
            </IconButton>
        </>
    )
}

export default Actions