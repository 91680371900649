import React from 'react';
import {Box, Grid, List} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import BarChartIcon from '@mui/icons-material/BarChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ContactsIcon from '@mui/icons-material/Contacts';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';
import ScienceRoundedIcon from '@mui/icons-material/ScienceRounded';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import ResponsiveImage from "../../../common/ResponsiveImage";
import SidebarButton from "./SidebarButton";
import {DRAWERWITH} from "../constants";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import {styled, useTheme} from "@mui/material/styles";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',

}));

const Sidebar = ({ handleDrawerClose, open }) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const {actualUser:{ role }} = useContext(AuthContext)
    const menu = [
        {
            'text': 'Principal',
            'icon': <HomeIcon fontSize='small'/>,
            'roleCanSee': ['Propietario', 'Administrador', 'Gestor de contenido', 'Analista de contenido'],
            'path': '/'
        },
        {
            'text': 'Agente virtual',
            'icon': <SmartToyIcon fontSize='small'/>,
            'roleCanSee': ['Propietario', 'Administrador', 'Gestor de contenido'],
            'path': '/agente'
        },
        {
            'text': 'Clientes',
            'icon': <ContactsIcon fontSize='small'/>,
            'roleCanSee': ['Propietario', 'Administrador', 'Analista de contenido'],
            'path': '/reportes'
        },
        {
            'text': 'Configuración',
            'icon': <SettingsIcon fontSize='small'/>,
            'roleCanSee': ['Propietario', 'Administrador'],
            'path': '/configuracion'
        },
        {
            'text': 'Acceso',
            'icon': <GroupsIcon fontSize='small'/>,
            'roleCanSee': ['Propietario', 'Administrador'],
            'path':'/acceso'
        },
    ]

    const drawer = (
        <Box sx={{
            background: 'linear-gradient(to right bottom, #008bdc, #3cc2b9)',
            height:'100%',
        }}>
            <DrawerHeader sx={{textAlign: 'center'}}>
                <ResponsiveImage
                    onClick={() => handleDrawerClose()}
                    alt={'ASSISTANT'}
                    src={"/img/assistant_color.png"}
                    style={{maxWidth:'50%', marginTop: '10%'}}/>
            </DrawerHeader>
            <Divider/>
            <List>
                {menu.map((menu, index) => {
                    return menu.roleCanSee.includes(role) && (
                        <SidebarButton key={`side-button-${index}`} icon={menu.icon}
                                       onClick={()=> navigate(menu.path)}
                                       text={menu.text}/>
                    )
                })}
            </List>
        </Box>
    )

    return (
        <Box 
            component="nav"
            sx={{ width: { sm: DRAWERWITH }, flexShrink: { sm: 0 } }}
            aria-label="menu"
        >
            <Drawer
                variant="temporary"
                open={open}
                onClose={handleDrawerClose}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: DRAWERWITH,
                    },
                }}
                >
                {drawer}
            </Drawer>

            <Drawer
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': {
                        width: DRAWERWITH,
                        boxSizing: 'border-box',
                    }
                }}

                variant="permanent"
                open
            >
                {drawer}

            </Drawer>

        </Box>
        
    );
};

export default Sidebar;
