import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import HistoryIcon from '@mui/icons-material/History';
import {IconButton} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import {formatISODate} from "../../../common/utils/utils";

export const ReportTable = ({report, rowCount, paginationModel, setPaginationModel, loading, onClickHistory}) => {
  const  data = {
    columns: [
      { field: 'Cedula', headerName: 'Documento de identidad', flex:1,
        renderCell: (value) => {
          if (value.value === "") {
            return (<span style={{color:"red"}}>no hay datos registrados</span>);
          }
          return value.value;
        },
      },
      { field: 'Nombre', headerName: 'Nombres y apellidos', flex:1,
        renderCell: (value) => {
          if (value.value === "") {
            return (<span style={{color:"red"}}>no hay datos registrados</span>);
          }
          return value.value;
        },
      },
      { field: 'Email', headerName: 'Correo Electrónico', flex:1,
        renderCell: (value) => {
          if (value.value === "") {
            return (<span style={{color:"red"}}>no hay datos registrados</span>);
          }
          return value.value;
        },
      },
      { field: 'Whatsapp', headerName: 'No. Móvil', flex:1,
        renderCell: (value) => {
          if (value.value === "") {
            return (<span style={{color:"red"}}>no hay datos registrados</span>);
          }
          return value.value;
        },
      },
      { field: 'createdAt', headerName: 'Fecha y hora', flex:1,
        renderCell: function(value) {
          return  formatISODate(value.value) 
          
        }
      }, 
      { field: 'Accept', headerName: '¿Acepto política de privacidad?', flex:1, 
        renderCell: function(value) {
          return value.value?'Si':'No'
        }
      }, 
      {
        field: 'action',
        headerName: 'Historial',
        flex:1,
        sortable: false,
        disableClickEventBubbling: true,
        renderCell: (row) => {
          return (
              <Tooltip title="Ver Historial" placement="right">
                <IconButton onClick={()=> onClickHistory(row.row)} aria-label="edit" color="warning" size="small">
                  <HistoryIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
          )
        }
      }
    ],
    rows: report,
    
  };
 
  return (
        <Box sx={{height: 500, width: "100%"}}>
          <DataGrid
              density={"compact"}
              loading={loading}
              getRowId={(row) => row._id}
              pagination
              rowCount={rowCount}
              pageSizeOptions={[20]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              paginationMode="server"
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              {...data}
              slotProps={{
                toolbar: {
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true },
                }}}
              slots={{ toolbar: GridToolbar, loadingOverlay: LinearProgress,}}/>
        </Box>
  );
}