import React, {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import {styled, useTheme} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Stack, Typography} from '@mui/material'
import {customFlowApi} from "../../../consumer/customFlowApi";
import {Box} from "@mui/material";
import Calendar from "../../../common/Calendar";
import {queryDate} from "../../../common/utils/utils";
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));



export const HistoryModal = ({ open, setOpen, contact }) => {
    const theme = useTheme();
    const [rangeSelected, setRangeSelected] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [chats, setChats] = useState([]);

    const reset = () => {
        setChats([])
        setEndDate(null)
        setStartDate(null)
        setRangeSelected(null)
        setLoading(false)
    }
    const dateRange = (date) => {
        const [start, end] = date;
        setStartDate(start)
        setEndDate(end)
        if(start && end){
            const query = queryDate(start, end)
                .replace('startDate', 'fechaInicio')
                .replace('endDate','fechaFin');
            setRangeSelected(query);
        }
    }

    const styleChat = {
        userMessage: {
            border:'1px solid grey',
            color: theme.palette.primary.contrastText,
            padding: theme.spacing(1),
            borderRadius: theme.spacing(1),
            marginBottom: theme.spacing(1),

        },
        botMessage: {
            backgroundColor: theme.palette.grey[300],
            padding: theme.spacing(1),
            borderRadius: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }

    useEffect(()=>{
        (async () => {
            if(rangeSelected){
                setLoading(true);
                const response = await customFlowApi.getRangeChats(
                    contact.Whatsapp, rangeSelected
                );
                if(response.status === 200){
                    setChats(response.data.conversaciones);
                }
                setLoading(false);
            }
        })();
    }, [rangeSelected]);
    useEffect(() => {
        (async () => {
            if(!open){
                reset();
            }
            if(contact && open){
                setLoading(true);
                const response = await customFlowApi.getChats(
                    contact.Whatsapp
                );
                if(response.status === 200){
                    setChats(response.data.conversaciones);
                }
                setLoading(false);
            }
        })();
    }, [open]);
    return (
        <>
            <BootstrapDialog
                maxWidth={'md'}
                onClose={() => setOpen(false)}
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} >
                    HISTORIAL CONVERSACIONAL DE {contact?.Nombre.toUpperCase()}
                </DialogTitle>
                <DialogContent>
                    <Stack direction={'column'}>
                        <Box mb={2} minWidth={500}>
                            <Calendar
                                placeholderText='Seleccionar rango'
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                selected={startDate}
                                onChange={dateRange}/>
                        </Box>
                        <Box>
                            {!loading ?
                                chats.length > 0 ? (
                                    chats.map((chat, index) => (
                                        <Box key={index}
                                             sx={chat.tipo === 'usuario' ? styleChat.userMessage : styleChat.botMessage}>
                                            <Typography variant="body1" >
                                                {chat.mensaje}
                                            </Typography>
                                            <Typography variant="caption" textAlign='right' >
                                                {chat.tipo === 'usuario' ? contact?.Nombre.toUpperCase() : chat.tipo}
                                            </Typography>
                                        </Box>
                                    ))
                                ) : (
                                        <Box sx={styleChat.botMessage}>
                                            <Typography variant="body1" >
                                                No hay conversaciones para mostrar
                                            </Typography>
                                        </Box>
                                    )
                             : (
                                    <Box sx={styleChat.botMessage}  >
                                        <CircularProgress color="inherit" />
                                    </Box>
                            )}

                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)}>
                        cerrar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}