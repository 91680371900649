import * as React from 'react';
import Container from "@mui/material/Container";

export default function CuadroLateral({ children }) {
  return (
      <Container component="main" maxWidth="lg" sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
      }}>
          {children}
      </Container>
  );
}
