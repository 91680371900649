import React, {useContext, useState} from "react";
import Grid from "@mui/material/Grid";
import {Box, Typography, Tabs, Tab} from "@mui/material";
import ListNewIntents from "./components/ListNewIntents";
import ListNewAgents from "./components/ListNewAgents";
import {AuthContext} from "../../../context/AuthContext";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const NewAgentView = () => {

    const { actualUser:{role} } = useContext(AuthContext);
    const canTodoThings = ["Propietario", "Administrador", "Gestor de contenido"].includes(role);
    const [value, setValue] = useState(canTodoThings ? 'Agentes' : 'Intenciones');


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const panels = {
        'Agentes' : <ListNewAgents value={value} index={'Agentes'} />,
        'Intenciones' : <ListNewIntents value={value} index={'Intenciones'} />,
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" sx={{ color: "#2596BE" }} textAlign={'center'}>
                        Gestión del agente
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Tabs value={value} onChange={handleChange} centered>
                        { canTodoThings ? (
                            <Tab label="Agentes" value={'Agentes'} {...a11yProps('Agentes')} />
                        ) : null }
                        <Tab label="Intenciones" value={'Intenciones'} {...a11yProps('Intenciones')} />
                    </Tabs>
                    { panels[value] }
                </Grid>
            </Grid>
        </Box>
    )
}
