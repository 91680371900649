import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {DRAWERWITH} from "../components/constants";
import {ToolBar} from "../components/toolbar/ToolBar";
import Sidebar from "../components/sidebar/SideBar";
import Container from "@mui/material/Container";
import Alert from "../../common/Alert";
import {Box, Snackbar} from "@mui/material";
import {useContext} from "react";
import {HomeContext} from "../context/HomeContext";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Button from "@mui/material/Button";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        
    }),
);

export const HomePage = ( {children} ) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const {alert, setAlert} = useContext(HomeContext)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex'}}>
            <CssBaseline />
            <ToolBar open={open} handleDrawerOpen={handleDrawerOpen}/>
            <Sidebar open={open} handleDrawerClose={handleDrawerClose} />

            <Main>
                <Container sx={{
                    marginTop: '65px', width: { sm: `calc(100% - ${DRAWERWITH}px)` }
                }}>
                    {children}
                    <Snackbar open={alert.open} autoHideDuration={6000}
                              onClose={() => setAlert(prevState => ({...prevState, open: false}))}>
                        <Alert onClose={() => setAlert(prevState => ({...prevState, open: false}))}
                               severity={alert.variant} sx={{ width: '100%' }}>
                            {alert.title}
                        </Alert>
                    </Snackbar>
                </Container>
            </Main>
        </Box>
    );
}


