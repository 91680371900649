import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Container';
import consumer from "../../consumer/authModuleAPI";
import { CircularProgress, Modal } from '@mui/material';
import BoxForm from "./BoxForm";
import Copyright from "../../common/Copyright";


export default function SignUp({home, handleSign, handleSignIn, handleAlert}) {
    const [isLoading, setIsLoading] = React.useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if(data.get('password') === data.get('check-password')){
            let disableSign = false;
            setIsLoading(true)
            consumer.getSettings((res)=>{
                if (res && res.data.length > 0) {
                    disableSign = res.data[0].disableSign
                }
                if(!disableSign){
                    consumer.createUser({
                        username: data.get('email'),
                        password: data.get('password'),
                        role: "Analista de contenido",
                        lastIngress: gateDate(),
                        name: "",
                    }, (res) => {
                        if (res) {
                            localStorage.setItem('user', JSON.stringify(res.data))
                            handleSign(res.data)
                            handleAlert("Creado con éxito", 'success')

                        } else {
                            handleAlert("Error de conexión", 'error')
                        }
                    })
                }else{
                    handleAlert("El administrador ha deshabilitado el autoregistro, solicitar registro manual", 'info')
                }
            })
            setIsLoading(false)
        }else{
            setIsLoading(false)
            handleAlert("Las contraseñas no coinciden", 'error')
        }
    };

    const gateDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yy = today.getFullYear();
        return `${mm}/${dd}/${yy}`;
    }

    return (
        <>
            <BoxForm onSubmit={handleSubmit} >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo"
                            name="email"
                            autoFocus
                            autoComplete="email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="check-password"
                            label="Confirmar contraseña"
                            type="password"
                            id="check-password"
                            autoComplete="new-password"
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disableElevation
                    sx={{ mt: 1, mb: 2, fontSize: 18, textTransform: "capitalize", fontWeight: "bold", color: "#fff", bgcolor: "#00B84F", }}
                >
                    Registrarse
                </Button>

                <Grid container>
                    {/* <Grid item xs={12}>
                        <Typography variant="body1" color={"#2596BE"} textAlign={'center'} ml={8} fontSize={14} mt={1}>
                            ¿Ya tienes una cuenta?
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Button variant="text"
                                sx={{ fontWeight: "bold", textTransform: 'initial', alignSelf: "end", pt: 1 }}
                                onClick={ () => {
                                    handleSignIn("sigin")
                                }
                                }>
                            Ingresa
                        </Button>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid >
                    <Grid item>
                        <Button sx={{fontWeight: "bold", textTransform: 'initial'}} variant="text" onClick={() => home()}>Volver</Button>
                    </Grid>
                </Grid>
            </BoxForm>
            <Copyright sx={{ mt: 1 }} />
            <Modal open={isLoading} sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
            }}>
                <CircularProgress />
            </Modal>
        </>
    );
}