import React from "react";
import ResponsiveImage from "../../common/ResponsiveImage";
import Grid from "@mui/material/Grid";
const BoxForm = ({ children, onSubmit}) => {
    return (
        <>
            <Grid item xs={12} md={12} lg={12} textAlign={'center'} p={2}>
                <ResponsiveImage style={{maxWidth: '50%'}} alt={'LOGO ABRAHAN CHATBOT'} src={"/img/logo.png"}/>
            </Grid>
            <Grid container justifyContent={'center'}>
                <Grid item xs={12} md={4} lg={4}
                      component="form"
                      onSubmit={onSubmit} noValidate bgcolor={"white"} p={2} borderRadius={5}>
                    { children }
                </Grid>
            </Grid>
        </>
    );
}

export default BoxForm