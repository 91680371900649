import {CustomTabPanel} from "./CustomTabPanel";
import React, {useContext, useEffect, useState} from "react";
import {
    Box, Card,
    Divider,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { customFlowApi } from '../../../../consumer/customFlowApi';
import ButtonModal from "./ButtonModal";
import TextField from "@mui/material/TextField";
import {AddStructKeyWordResponse} from "./AddStructKeyWordResponse";
import {HomeContext} from "../../../context/HomeContext";
import IntentsTable from "./IntentsTable";

const ListNewIntents = ({value, index}) => {
    const {alert, setAlert, actualAgent} = useContext(HomeContext)
    const baseStructIntent = {
        "structList": null,
        "structButton": null,
        "keywords": [],
        "responses": [],
        "blockname": "",
        "number": "",
        "type": "text",
        "template": null,
    }
    const baseStructList = {
        field: '',
        secondaryField:'',
        principal:{
            textbody:"",
            textfooter:"",
            textButtonAction:"",
            titleoption:"",
            listoption:[]         
        }
    }
    const baseStructButton = {
        field: '',
        principal:{
            textbody: "",
            listButton: []
        }
    }

    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(null);
    const [titleModal ,setTitleModal] = useState('Registrar intención')
    const [intent ,setIntent] = useState({...baseStructIntent})
    const [intents ,setIntents] = useState([])
    const [isLoading, setIsLoading ] = useState(true)
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [ keyWords, setKeyWords ] = useState([]);
    const [ responses, setResponses ] = useState([]);
    const [ structButtons, setStructButtons ] = useState({...baseStructButton});
    const [ structList, setStructList ] = useState({...baseStructList});
    const [ structKeywordResponse, setStructKeywordResponse ] = useState({
        'response': '',
        'keyword': ''
    });

    const deleteItemList = (list, callBackSet, index) => {
        const copyList = [...list]
        copyList.splice(index, 1)
        callBackSet([...copyList])
    }

    const handleChangeIntent = (event, key) => {
        setIntent(prevState => ({
            ...prevState,
            [key]: event.target.value
        }))
    }

    const getIntents =  () => {
        if(actualAgent) {
            setIsLoading(true);
            customFlowApi.getPageIntents(actualAgent.agent.NumeroWhatsapp, paginationModel.page+1).then(response => {
                if(response.status === 200){
                    setIntents(response.data.intenciones);
                    setRowCount(response.data.totalIntenciones);
                }else{
                    setAlert({...alert,
                        title: `Hubo un problema con la solicitud status: ${response.status}`,
                        open: true,
                        variant: 'error'
                    });
                }
                setIsLoading(false)
            }).catch(e => console.log(e))
        }else {
            setAlert({...alert,
                title: `Por favor selecciona un agente en la parte superior`,
                open: true,
                variant: 'warning'
            });
            setIsLoading(false);
        }

    }
    const deleteIntent =  (intent) => {
       customFlowApi.deleteIntent(intent._id).then(response => {
           setAlert({...alert, title: 'Eliminado correctamente', open: true})
           getIntents()
       }).catch( e => console.log(e))
    }
    const sendIntent = () => {
        const copyStructList = {...structList.principal}
        const copyStructButton = {...structButtons.principal}
        copyStructButton.listButton = copyStructButton.listButton.map(item => ({title: item}))
        copyStructList.listoption = copyStructList.listoption.map(item => ({title: item.title, description: item.description}))
        let intentCopy = {
            ...{...intent},
            keywords: [...keyWords],
            responses: intent.type !== 'text' ? null : [...responses],
            structList: intent.type !== 'list' ? null : copyStructList,
            structButton: intent.type !== 'button' ? null : copyStructButton
        }
        if (isEdit){
            customFlowApi.updateIntent(intentCopy._id, intentCopy).then(response => {
                setOpen(false)
                setAlert({...alert, title: 'Actualizado correctamente', open: true})
                getIntents()
            }).catch( e => console.log(e))
        }else{
            customFlowApi.createIntent(intentCopy).then(response => {
                setAlert({...alert, title: 'creado correctamente', open: true})
                getIntents()
            }).catch( e => console.log(e))
        }

    }

    const formatListOption = (items) => {
        return items.map(list=> { return {title: list.title, description: list.description}})
    }

    const updateIntent = (intentCreated) => {
        setIsEdit(true)
        setTitleModal('Actualizar Intención')
        const structButton = intentCreated.structButton !== null ? {...intentCreated.structButton}: baseStructButton.principal
        const structList = intentCreated.structList !== null ? {...intentCreated.structList} : baseStructList.principal
        structButton.listButton = structButton.listButton.map(item => item.title !== undefined ? item.title : item)
        structList.listoption = formatListOption(structList.listoption)
       
       setIntent({...intentCreated})
        setStructButtons({
            ...baseStructButton,
            principal: structButton
        })
        setStructList({
            ...baseStructList,
            principal: structList
        })
        setKeyWords(intentCreated.keywords)
        setOpen(true)
    }

    const cancel = () => {
         const reset = async () => {
            setIntent({
                ...baseStructIntent
            })
            setStructButtons({...baseStructButton})
            setStructList({...baseStructList})
            setKeyWords([])
        }
        reset()
        setOpen(false)
        setIsEdit(false)

    }

    const addToList = (key, list, state, updateList) => {
        if(state[key] !== ''){
            const copy = [...list]
            copy.push(state[key].trim())
            updateList(copy)
        }
    }

    const addToListSecondary = (key, secondaryKey, list, state, updateList) => {
        if(state[key] !== ''){
            const copy = [...list]
            copy.push({title: state[key].trim(), description:state[secondaryKey].trim()})
            updateList(copy)
        }
    }

   const updateItemList =(key, secondaryKey, item, index, list, state, updateList)=>{
        if(state[key] !== ''){
            const copy = [...list]
            copy[index] = {title: item.title.trim(), description:item.description.trim()}
            updateList(copy)
        }
    }

    useEffect(() => {
        getIntents()
    }, [value, actualAgent, paginationModel.page]);
    return (
        <CustomTabPanel value={value} index={index}>
            <Box flexGrow={1}>
                <Grid container spacing={1}>
                    <ButtonModal
                        open={open}
                        setOpen={() => {
                            setTitleModal('Registrar Intención')
                            setOpen(true)
                            setIsEdit(false)
                        }}
                        refresh={() => getIntents()}
                        buttonText={'Nueva intención'}
                        modalTitle={titleModal}
                        onCancel={cancel}
                        onClick={sendIntent}
                        props={{fullWidth:true, maxWidth: 'lg'}}>
                            <Grid container spacing={1} flexGrow={1}>
                                <Grid item xs={12} md={12} >
                                    <RadioGroup
                                        sx={{
                                            justifyContent: 'center'
                                        }}
                                        row
                                        value={intent.type}
                                        onChange={(event) => handleChangeIntent(event, 'type')}
                                    >
                                        <FormControlLabel
                                            value="text"
                                            control={<Radio />}
                                            label="Texto"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="button"
                                            control={<Radio />}
                                            label="Botón"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="list"
                                            control={<Radio />}
                                            label="Lista"
                                            labelPlacement="top"
                                        />

                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={1} justifyContent={'center'}>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                type={'number'}
                                                value={intent.number}
                                                variant="standard"
                                                margin="normal"
                                                label="Número"
                                                size="small"
                                                onChange={(e)=> handleChangeIntent(e, 'number') }/>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                value={intent.blockname}
                                                variant="standard"
                                                margin="normal"
                                                label="Nombre"
                                                size="small"
                                                onChange={(e)=> handleChangeIntent(e, 'blockname') }/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={1}>
                                        {intent.type === 'text' && (
                                            <Grid item xs={12} md={intent.type !== 'text' ? 8 : 6}>
                                            <Divider/>
                                                <Card sx={{padding:3}}>
                                                    <Typography variant="h5" component="h5" textAlign={'center'}>
                                                        Respuestas
                                                    </Typography>
                                                    <AddStructKeyWordResponse
                                                        value={structKeywordResponse.response}
                                                        label={'Respuestas'}
                                                        items={responses}
                                                        callBackDeleteItem={
                                                            (item, index) => deleteItemList(
                                                                responses,
                                                                setResponses,
                                                                index
                                                            )
                                                        }
                                                        callBackSetList={
                                                            () => {
                                                                addToList(
                                                                    'response',
                                                                    responses,
                                                                    structKeywordResponse,
                                                                    setResponses

                                                                )
                                                                setStructKeywordResponse(prevState => ({
                                                                    ...prevState,
                                                                    response: ''
                                                                }))
                                                            }
                                                        }
                                                        callBackSetValue={
                                                            (value) => setStructKeywordResponse(
                                                                (
                                                                    prevState => ({
                                                                        ...prevState,
                                                                        response: value
                                                                    })
                                                                )
                                                            )
                                                        }
                                                    />
                                                </Card>
                                        </Grid>)}
                                        {intent.type === 'button' && (
                                            <Grid item xs={12} md={intent.type !== 'text' ? 8 : 6}>
                                                <Divider/>
                                                <Card sx={{padding:3}}>
                                                <Typography variant="h5" component="h5" textAlign={'center'}>
                                                    Botones
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    value={structButtons.principal.textbody}
                                                    variant="standard"
                                                    margin="normal"
                                                    label="Texto de mensaje"
                                                    size="small"
                                                    onChange={(e)=> setStructButtons(
                                                        prevState => ({
                                                            ...prevState,
                                                            principal: {
                                                                ...prevState.principal,
                                                                textbody: e.target.value
                                                            }
                                                        })
                                                    ) }/>
                                                <AddStructKeyWordResponse
                                                    value={structButtons.field}
                                                    label={'Label Botón'}
                                                    callBackDeleteItem={
                                                        (item, index) => deleteItemList(
                                                            structButtons.principal.listButton,
                                                            (newList) => {
                                                                setStructButtons(
                                                                    prevState => ({
                                                                        ...prevState,
                                                                        principal: {
                                                                            ...prevState.principal,
                                                                            listButton: newList
                                                                        }
                                                                    })
                                                                )
                                                            },
                                                            index
                                                        )
                                                    }
                                                    items={structButtons.principal.listButton}
                                                    callBackSetList={
                                                        () => {
                                                            if (structButtons.principal.listButton.length < 3) {
                                                                addToList(
                                                                    'field',
                                                                    structButtons.principal.listButton,
                                                                    structButtons,
                                                                    (list) => setStructButtons(
                                                                        prevState => ({
                                                                            ...prevState,
                                                                            principal: {
                                                                                ...prevState.principal,
                                                                                listButton: list
                                                                            }
                                                                        })
                                                                    )
                                                                )
                                                            }
                                                            setStructButtons(
                                                                prevState => ({
                                                                    ...prevState,
                                                                    field: ''
                                                                })
                                                            )
                                                        }
                                                }
                                                    callBackSetValue={
                                                        (value) => setStructButtons(
                                                        prevState => ({
                                                                ...prevState,
                                                                field: value
                                                            })
                                                        )
                                                    }
                                                /></Card>
                                            </Grid>
                                        )}
                                        {intent.type === 'list' && (
                                            <Grid item xs={12} md={intent.type !== 'text' ? 8 : 6}>
                                                <Divider/>
                                                <Card sx={{padding:3}}>
                                                    <Typography variant="h5" component="h5" textAlign={'center'}>
                                                        Lista
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        value={structList.principal.textbody}
                                                        variant="standard"
                                                        margin="normal"
                                                        label="Texto de mensaje"
                                                        size="small"
                                                        onChange={(e)=> setStructList(
                                                            prevState => ({
                                                                ...prevState,
                                                                principal: {
                                                                    ...prevState.principal,
                                                                    textbody: e.target.value
                                                                }
                                                            })
                                                        ) }/>
                                                    <Grid item xs={12} md={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <TextField
                                                                    fullWidth
                                                                    value={structList.principal.textfooter}
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    label="Footer"
                                                                    size="small"
                                                                    onChange={(e)=> setStructList(
                                                                        prevState => ({
                                                                            ...prevState,
                                                                            principal: {
                                                                                ...prevState.principal,
                                                                                textfooter: e.target.value
                                                                            }
                                                                        })
                                                                    ) }/>
                                                            </Grid>
                                                            <Grid item>
                                                                <TextField
                                                                    fullWidth
                                                                    value={structList.principal.textButtonAction}
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    label="Texto botón de acción"
                                                                    size="small"
                                                                    onChange={(e)=> setStructList(
                                                                        prevState => ({
                                                                            ...prevState,
                                                                            principal: {
                                                                                ...prevState.principal,
                                                                                textButtonAction: e.target.value
                                                                            }
                                                                        })
                                                                    ) }/>
                                                            </Grid>
                                                            <Grid item>
                                                                <TextField
                                                                    fullWidth
                                                                    value={structList.principal.titleoption}
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    label="Título de opciones"
                                                                    size="small"
                                                                    onChange={(e)=> setStructList(
                                                                        prevState => ({
                                                                            ...prevState,
                                                                            principal: {
                                                                                ...prevState.principal,
                                                                                titleoption: e.target.value
                                                                            }
                                                                        })
                                                                    ) }/>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <AddStructKeyWordResponse
                                                        value={structList.field}
                                                        additionalValue={structList.secondaryField}
                                                        editList
                                                        label={'Opción'}
                                                        labelSecondary={'Descripción'}
                                                        items={structList.principal.listoption}
                                                        callBackDeleteItem={
                                                            (item, index) => deleteItemList(
                                                                structList.principal.listoption,
                                                                (newList) => {
                                                                    setStructList(
                                                                        prevState => ({
                                                                            ...prevState,
                                                                            principal: {
                                                                                ...prevState.principal,
                                                                                listoption: newList
                                                                            }
                                                                        })
                                                                    )
                                                                },
                                                                index
                                                            )
                                                        }
                                                        callBackSetList={
                                                            () => {
                                                                addToListSecondary(
                                                                    'field',
                                                                    'secondaryField',
                                                                    structList.principal.listoption,
                                                                    structList,
                                                                    (list) => setStructList(
                                                                        prevState => ({
                                                                            ...prevState,
                                                                            principal: {
                                                                                ...prevState.principal,
                                                                                listoption: list
                                                                            }
                                                                        })
                                                                    )
                                                                    
                                                                )
                                                                setStructList(
                                                                    prevState => ({
                                                                        ...prevState,
                                                                        field: '', 
                                                                        secondaryField: ''                                                                
                                                                    })
                                                                )
                                                            }
                                                        }
                                                        callBackSetValue={
                                                            (value) => setStructList(
                                                                prevState => ({
                                                                    ...prevState,
                                                                    field: value
                                                                })
                                                            )
                                                        }
                                                        callBackSetValueAdditional={
                                                            (value) => setStructList(
                                                                prevState => ({
                                                                    ...prevState,
                                                                    secondaryField: value
                                                                })
                                                            )
                                                        }
                                                        callBackModifyItem={
                                                            (item, index) => {
                                                                updateItemList(
                                                                    'field', 
                                                                    'secondaryField',
                                                                    item,
                                                                    index,
                                                                    structList.principal.listoption,
                                                                    structList,
                                                                    (list) => setStructList(
                                                                        prevState => ({
                                                                            ...prevState,
                                                                            principal: {
                                                                                ...prevState.principal,
                                                                                listoption: list
                                                                            }
                                                                        })
                                                                    )
                                                                )
                                                                setStructList(
                                                                    prevState => ({
                                                                        ...prevState,
                                                                        field: '',  
                                                                        secondaryField:''                                                                  
                                                                    })
                                                                )
                                                            }
                                                        }
                                                    />

                                                    

                                                </Card>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={intent.type !== 'text' ? 4 : 6} >
                                            <Divider/>
                                            <Card sx={{padding:3}}>


                                            <Typography variant="h5" component="h5" textAlign={'center'}>
                                                Intención
                                            </Typography>
                                            <AddStructKeyWordResponse
                                                value={structKeywordResponse.keyword}
                                                label={'Clave'}
                                                items={keyWords}
                                                callBackDeleteItem={
                                                    (item, index) => deleteItemList(
                                                        keyWords,
                                                        setKeyWords,
                                                        index
                                                    )
                                                }
                                                callBackSetList={
                                                    () => {
                                                        addToList(
                                                            'keyword',
                                                            keyWords,
                                                            structKeywordResponse,
                                                            setKeyWords
                                                        )
                                                        setStructKeywordResponse(prevState => ({
                                                            ...prevState,
                                                            keyword: ''
                                                        }))
                                                    }
                                                }
                                                callBackSetValue={(value) => setStructKeywordResponse((
                                                    prevState => ({
                                                        ...prevState,
                                                        keyword: value
                                                    })))}
                                            /></Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                    </ButtonModal>
                    <Grid item xs={12} md={12}>
                        <IntentsTable
                            rowCount={rowCount}
                            paginationModel={paginationModel}
                            rowSelectionModel={rowSelectionModel}
                            setPaginationModel={setPaginationModel}
                            setRowSelectionModel={setRowSelectionModel}
                            loading={isLoading}
                            intents={intents}
                            deleteCallBack={(item) => deleteIntent(item) }
                            editCallback={(item) => updateIntent(item)}
                        />
                        {/*<CustomList  callApi={ getIntents }*/}
                        {/*             renderText={name}*/}
                        {/*             items={intents}*/}
                        {/*             isLoading={isLoading}*/}
                        {/*             actions={ (item) => <Actions*/}
                        {/*                item={item}*/}
                        {/*                deleteCallBack={(item) => deleteIntent(item) }*/}
                        {/*                editCallback={(item) => updateIntent(item)} />}*/}
                        {/*/>*/}
                    </Grid>
                </Grid>

            </Box>
        </CustomTabPanel>
    );
}

export default ListNewIntents