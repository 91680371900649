import {format, parseISO } from "date-fns";

export const queryDate = (start, end) => {
    const endCustom = addHoursToDate(end, 24)
    const formatStart = format(start, "yyyy-MM-dd");
    const formatEnd = format(endCustom, "yyyy-MM-dd");
   
    return `startDate=${formatStart}T05:00:00.000Z&endDate=${formatEnd}T05:00:00.000Z`;
}

function addHoursToDate(objDate, intHours) {
    var numberOfMlSeconds = objDate.getTime();
    var addMlSeconds = (intHours * 60) * 60000;
    var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
    return newDateObj;
}

export const formatISODate = (date) => {
    const result = parseISO(date)
    return  format(result, "dd/MM/yyyy HH:mm:ss");
}

export const request = async (url, method, token) => {
    const response = await fetch(url, {
            method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
            }
        });
    return {
        status: response.status,
        data: await response.json()
    }
}