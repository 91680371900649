import React, { useState } from 'react'
import consumer from '../../../consumer/authModuleAPI'
import { useEffect } from 'react'
import { Box, Switch, Typography } from '@mui/material'

export const SettingsView = () => {

  const [settings, setSettings] = useState(false)
  const [disableSign, setDisableSign] = useState(false)

  useEffect(() => {
    consumer.getSettings((res)=>{
      if (res && res.data.length > 0) {
          setSettings(res.data[0])
          setDisableSign(settings.disableSign)
      } else {
          alert("Something goes wrong");
      }
    })
  }, [settings.disableSign])

  const handleDisableSign = (isDisable) => {
    consumer.sendSettings({
      ...settings,
      disableSign: isDisable,
    },(res)=>{
      if (res) {
        alert("La configuración se actualizó correctamente");
        setDisableSign(isDisable)
      } else {
        alert("Something goes wrong");
      }
  })
  }

  return (
    <Box height={'100vh'} mt={1}>
      <Typography variant="h3" sx={{
        color: "#2596BE", px: 1.5,
        borderRadius: 4
      }} >
        Configuraciones y preferencias
      </Typography>
      <Typography mt={3} mb={1} variant="h4" sx={{
        color: "#0cbc5c", px: 1.5,
        borderRadius: 4
      }} >
        Autenticación
      </Typography>
      <Typography variant="body" sx={{
        color: "#2596BE", px: 1.5,
        borderRadius: 4
      }} >
        Auto registro
      </Typography>
      <Switch
        checked={!disableSign}
        onChange={(e)=>handleDisableSign(!(e.target.checked))}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </Box>
  )
}
