import {Button, Stack, Box, Typography, Divider, Snackbar} from '@mui/material';
import CuadroLateral from '../components/CuadroLateral';
import SignIn from '../components/SingIn';
import SignUp from '../components/SingUp';
import UpdatePassword from '../components/UpdatePasswordUser';
import { AuthContext } from '../../context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom"
import consumer from '../../consumer/authModuleAPI';
import Recovery from '../components/Recovery';
import React from "react";
import Grid from "@mui/material/Grid";
import ResponsiveImage from "../../common/ResponsiveImage";
import Copyright from "../../common/Copyright";
import Alert from "../../common/Alert";

export default function Auth() {

  const { token } = useParams();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [typAlert, setTypeAlert] = useState('success');
  const [messageAlert, setMessageAlert] = useState('');
  const [ lateralBox, setLateralBox] = useState(false)
  const [ component, setComponent] = useState(undefined)
  const { actualUser, setActualUser} = useContext(AuthContext)
  const [ updatemodal, setUpdatemodal] = useState(false)
  const [ disableSign, setDisableSign] = useState(false)
  const [ recoveryEmail, setRecoveryEmail] = useState(false)
  const navigate = useNavigate();
  const handleAlert = (message, type) => {
    setIsAlertOpen(true);
    setMessageAlert(message)
    setTypeAlert(type)
  }
  useEffect(()=>{
    if(token){
      consumer.validateToken( token, (res)=>{
        if (res) {
          const {msg, email} = res
          if(email){
            setLateralBox(true)
            setComponent('forgotten')
            setRecoveryEmail(email)
          }else{
            handleAlert(msg, 'warning')
            navigate('/login');
            setRecoveryEmail(false)
          }
        }
      })
    }
  }, [token, navigate])

  const hideBox = () => {
    setLateralBox(false)
    setComponent("")
    setActualUser(null)
  }

  useEffect(() => {
    if (actualUser) {
      if(actualUser.isAdministrador){
        setLateralBox(false)
        setComponent("")
      } else {
        setComponent("userResult")
      }
    }
  }, [actualUser])

  useEffect(() => {
    consumer.getSettings((res)=>{
      if (res && res.data.length > 0) {
          console.log(res)
          setDisableSign(res.data[0].disableSign)
      }
    })
  }, [])

  return (
      <Box sx={{
        height: '100vh',
        overflowY: 'auto',
        background: 'linear-gradient(to right bottom, #008bdc, #3cc2b9)',
        display:"flex",flexDirection:"column"}}>
        <UpdatePassword user={undefined} state={updatemodal} setState={setUpdatemodal} setDisable={false} />
        <Box height={'10%'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box ml={2} alignSelf={"center"} onClick={hideBox}>
            <img src={"/img/asistant-black.png"} alt="IMAGEN APP" width={50} />
          </Box>
          <Stack spacing={2} direction="row" my={2} mr={3} >
            <Button variant="contained" sx={{ color: "#fff", fontWeight: "bold", textTransform: 'capitalize' }}
                    disableElevation onClick={() => {
              console.log(lateralBox)
              setLateralBox(true)
              setComponent("sigin")
            }}>
              Ingresar
            </Button>
            {!disableSign && (
                  <Button variant="outlined" sx={{ color: "#fff", fontWeight: "bold", textTransform: 'capitalize' }}
                    onClick={() => {
                      setLateralBox(true)
                      setComponent("sigup")
                    }} >Registrarse</Button>
                )
            }
          </Stack>
        </Box>
        <Divider light />
        <Box sx={{ display: 'flex', flexDirection: 'row',
          alignItems: 'left', justifyContent: 'center'}}>
          { lateralBox ? (

                <CuadroLateral>
                  {component === "sigin" && (
                      <SignIn home={hideBox}
                              handleAlert={handleAlert}
                          disableSign={disableSign}
                          handleSign={setActualUser}
                          handleForgot={setUpdatemodal}
                          handleSignUp={setComponent} />
                  )}
                  {component === "sigup" && !disableSign &&
                      <SignUp handleAlert={handleAlert}
                              home={hideBox}
                              handleSign={setActualUser}
                              handleSignIn={setComponent} />}

                  {component === "forgotten" &&
                      <Recovery email={recoveryEmail} back={hideBox} />
                  }
                </CuadroLateral>

          ) :  (
              <>
                <Grid container p={2} spacing={{ xs: 3, md: 1 }} maxWidth="lg">
                  <Grid item xs={12} md={6} lg={6}>
                    <Grid item mb={5} mt={5} >
                      <ResponsiveImage alt={'LOGO ABRAHAN CHATBOT'} src={"/img/logo.png"}/>
                    </Grid>
                    <Grid>
                      <Typography variant="h4" textAlign={'center'} sx={{ color: "#FFF" }} >
                        ¡Bienvenido a la <span style={{ color: "#242853"}} >Transformación Digital </span> con <span style={{fontWeight: 'bold', color: "#242853"}}> Abraham Chatbot </span> su Asistente Virtual!
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography mt={'30%'} variant={'h4'} textAlign={'center'}  color={"#242853"}>
                      Una solución tecnológica <span style={{color: "#FFF"}} >inteligente e intuitiva</span> que potenciará su negocio.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} textAlign={'center'} mt={"15%"}>
                    <Copyright />
                  </Grid>
                </Grid>
              </>
          )}
        </Box>
        <Snackbar onClose={() =>setIsAlertOpen(false)} open={isAlertOpen} autoHideDuration={6000}>
          <Alert onClose={() =>setIsAlertOpen(false)} severity={typAlert} sx={{ width: '100%' }}>
            {messageAlert}
          </Alert>
        </Snackbar>
      </Box>

  );
}
