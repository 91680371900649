import Typography from '@mui/material/Typography';
import {Grid, Paper} from "@mui/material";
import Box from "@mui/material/Box";

import CircularProgress from '@mui/material/CircularProgress';
export function WidgetSummary({boardIcon, title, total, isLoading, iconColor}) {
    return (
        <Paper elevation={3} sx={{borderRadius:4, p:1, maxHeight: '85%'}}>
            <Box p={1} textAlign='center' >   
                    <Grid item xs container direction="column" >
                        <Grid itemx sx={{mb:3}}>
                            <span class="material-symbols-outlined" style={{fontSize:"6em", color:iconColor}}>
                                {boardIcon}
                            </span>   
                            <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                                {title}
                            </Typography>
                            {!isLoading ? (
                                <Typography variant="h3">
                                    {total}
                                </Typography>
                            ) : (
                                    <CircularProgress />
                                )
                            }
                        </Grid>      
                    </Grid>
              
            </Box>
        </Paper>
    );
}