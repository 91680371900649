import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { HomeContext } from '../../context/HomeContext';
import {customFlowApi} from '../../../consumer/customFlowApi';
import { useState } from 'react';


export const BotSelector = () => {

    const [agents, setAgents] = useState([])
    const {actualAgent, setActualAgent} = useContext(HomeContext)


    useEffect(()=>{
        customFlowApi.getChatBots().then(response => {
            if(response){
                if(actualAgent.index === 'default'){
                    if(response.data.length > 0){
                        setActualAgent({
                            index: 0,
                            agent:response.data[0]
                        })
                    }
                }
                if(response.data.length > 0){
                    setAgents(response.data)
                }

            }
        })
    },[])
  return (

          <Box
              sx={{
                  display: 'flex', flexDirection: 'row', alignItems: 'center',
                  border: 1,
                  borderRadius: 3,
                  borderColor: '#00B84F',
                  width: 300,
                  padding: 0.5,
                  margin: { xs: 2, md: 2 }
              }}
          >
              <SmartToyIcon/>
                <FormControl fullWidth>
                        <InputLabel id="agent-text">Agente</InputLabel>
                        <Select
                            defaultValue={actualAgent.index !== "default" && actualAgent.index !== null ? actualAgent.index: "default"}
                            labelId="agent-text"
                            id="agent-id"
                            value={actualAgent.index !== "default" && actualAgent.index !== null ? actualAgent.index : "default"}
                            label="Agente"
                            onChange={(e)=> setActualAgent({
                                index: e.target.value,
                                agent:agents[e.target.value]
                            })}
                            sx={{
                                height: 30,
                                backgroundColor: 'white',
                                color: '#00B84F'
                            }}
                        >
                            <MenuItem value={"default"}>Seleccionar</MenuItem>
                                {
                                    agents.map(({_id, usuario}, index)=>
                                        <MenuItem key={`${usuario}-${_id}`} value={index}>{usuario}</MenuItem>)
                                    
                                }
                        </Select>
                </FormControl>
          </Box>


  )
}
