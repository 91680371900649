import {Box, IconButton, ListItem, ListItemText, Typography, collapseClasses} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SaveIcon from '@mui/icons-material/Save';

export const AddStructKeyWordResponse = ({value, editList, callBackSetValue, callBackSetList, callBackDeleteItem, callBackSetValueAdditional, callBackModifyItem, items, label, labelSecondary, additionalValue}) => {
    
    const [customItems, setCustomItems] = useState([])

    useEffect(()=>{
        const _items = items.map((item)=>{
            if (typeof item === 'object' || item instanceof Object){
                return {...item, editing: false}
            }else{
                return item
            }
        })

        setCustomItems(_items)
    },[items])

    const updateItem = (item, index, key, secondaryKey)=>{
        if(item[key]!='' ){
            const _auxItems = [...customItems]
            _auxItems[index].editing=false
            setCustomItems(_auxItems)
            callBackModifyItem(item, index)
        }
    }

    const startUpdate = (index)=>{
        const _auxItems = [...customItems]
        _auxItems[index].editing=true
        setCustomItems(_auxItems)
    }

    const setItemValue = (value, index, key)=>{
        const _auxItems = [...customItems]
        _auxItems[index][key]=value
        setCustomItems(_auxItems)
    }
    
    return (
        <>
            <Box flexGrow={1} >
                <Grid container spacing={1} >
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            value={value}
                            variant="standard"
                            margin="normal"
                            label={label}
                            size="small"
                            onChange={(e) => callBackSetValue(e.target.value)}/>                     
                    </Grid>
                    {additionalValue!=undefined&&
                    
                    <Grid item xs={12} md={5}>
                    <TextField
                        fullWidth
                        value={additionalValue}
                        variant="standard"
                        margin="normal"
                        label={labelSecondary}
                        size="small"
                        onChange={(e) => callBackSetValueAdditional(e.target.value)}
                        />
                    </Grid>}
                    
                    <Grid item xs={12} md={1}>
                        <IconButton sx={{
                            marginTop: 4
                        }} color="primary" onClick={() => callBackSetList()}>
                            <AddIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            {customItems.map((item, index) => {
                return (
                    <ListItem
                        key={`item-${label}-${index}`}
                        secondaryAction={
                            <>
                                {editList&& item.editing ? 
                                    <IconButton edge="end" aria-label="edit"
                                        sx={{ mx: 1, color: '#6dc8c2' }}
                                        onClick={() => updateItem(item, index)}>
                                        <SaveIcon />
                                    </IconButton>
                                    :
                                    <IconButton edge="end" aria-label="edit"
                                        sx={{ mx: 1, color: '#6dc8c2' }}
                                        onClick={() => startUpdate(index)}>
                                        <BorderColorIcon />
                                    </IconButton>
                                    
                                }
                               
                                <IconButton edge="end" aria-label="delete"
                                            sx={{ mx: 1, color: '#fb6d6d' }}
                                            onClick={() => callBackDeleteItem(item, index)}>
                                    <DeleteIcon />
                                </IconButton>
                                
                            </>
            
                        }
                    >
                    {
                        (typeof item === 'object' || item instanceof Object) ?
                            <ListItemText
                                key={`item-${label}-${index}`}
                                primary={(typeof item.title === 'string' || item.title instanceof String) ? item.title : 'No hay descripción' }
                                secondary={(typeof item.description === 'string' || item.description instanceof String) ? 
                                    item.editing? 
                                        <TextField
                                        fullWidth
                                        value={item.description}
                                        variant="standard"
                                        margin="normal"
                                        label={labelSecondary}
                                        size="small"
                                        onChange={(e) => setItemValue(e.target.value, index, 'description')}/>  
                                    : 
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body1"
                                        color="text.primary"
                                    >
                                    {"Descripción: " + item.description}
                                    </Typography>
                                : ' '
                                }
                            />
                        :
                            <ListItemText
                                key={`item-${label}-${index}`}
                                primary={(typeof item === 'string' || item instanceof String) ? item : 'rrr' }
                            />
                    }

                    </ListItem>
                )
            })}
        </>

    )
}

