import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Container';
import consumer from "../../consumer/authModuleAPI";
import { CircularProgress, Modal } from '@mui/material';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Abraham Chatbot
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Recovery({ email, back }) {
    const [isLoading, setIsLoading] = React.useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if(data.get('password') === data.get('check-password')){
            setIsLoading(true)
            const newUser = {
                username: email,
                password: data.get('password'),
                passwordUserUpdated: true,
            }
            console.log(newUser)
            consumer.updateUser(newUser, (res) => {
                setIsLoading(false)
                if (res.data) {
                    console.log(res.data)
                    back()
                    alert("Contraseña actualizada exitosamente")
                } else {
                    alert("Error de conexión");
                }
            })
            
        }else{
            alert("Las contraseñas no coinciden");
        }
    };

    return (
        <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center',
            alignItems: 'center', pr: 3, /* bgcolor:"#A56" */
            }}>
            <img src={"/img/asistant.png"} alt="IMAGEN APP" width={"10%"} />
            <Typography variant="h3" fontWeight={'bold'} sx={{
            color: "#FFF", px: 1.5,
            borderRadius: 4,
            }} >
            Abraham
            </Typography>
            <Typography variant="h4" sx={{
            color: "#FFF", px: 1.5,
            borderRadius: 4,
            }} >
            Chatbot
            </Typography>
            </Box>
        <Box/>
            <Box
                sx={{
                    ml:20,
                    bgcolor: "#fff",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 2,
                    borderRadius: 5,
                    width: "60%",
                }}
            >
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                disabled
                                fullWidth
                                id="email"
                                name="email"
                                autoComplete="email"
                                value={email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Nueva contraseña"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="check-password"
                                label="Confirmar contraseña"
                                type="password"
                                id="check-password"
                                autoComplete="new-password"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disableElevation
                        sx={{ mt: 3, mb: 2, fontSize: 18, textTransform: "capitalize", fontWeight: "bold", color: "#fff", bgcolor: "#00B84F", }}
                    >
                        Actualizar contraseña
                    </Button>
                    <Divider/>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
            <Modal open={isLoading} sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
            }}>
                <CircularProgress />
            </Modal>
        </Container>
    );
}