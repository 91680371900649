import React from "react";
import {Box} from "@mui/material";
const ResponsiveImage = ({ src, alt, style, onClick }) => {

    return (
        <Box maxWidth={"100%"} onClick={onClick}>
            <img src={src} alt={alt} style={{...{maxWidth: '100%', height: 'auto', padding: 0, margin: 0}, ...style}}/>
        </Box>
    )
}

export default ResponsiveImage
