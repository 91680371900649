import { MenuItem, Select } from "@mui/material"
import consumer from "../../../consumer/authModuleAPI"
import {HomeContext} from "../../context/HomeContext";
import {useContext} from "react";

const { useState } = require("react")

export const Role = ({user}) => {
    const { setAlert } = useContext(HomeContext);

    let initRole = 40

    switch(user.role){
        case "Propietario":
            initRole = 10
            break;
        case "Administrador":
            initRole = 20
            break;
        case "Gestor de contenido":
            initRole = 30
            break;
        case "Analista de contenido":
            initRole = 40
            break;
        default:
            initRole = 40
            break;
    }

    const [userRole, setUserRole] = useState(initRole)

    const sendRole = ({target:{value}}) => {
        
        let newRole = ""
        switch(value){
            case 10:
                newRole = "Propietario"
                break;
            case 20:
                newRole = "Administrador"
                break;
            case 30:
                newRole = "Gestor de contenido"
                break;
            case 40:
                newRole = "Analista de contenido"
                break;
            default:
                newRole = "Sin definir"
                break;
        }

        const newUser = {
            ...user,
            role: newRole
        }

        consumer.updateUser({
            ...newUser,
        }, (res) => {
            if (res) {
                setUserRole(value)
                setAlert({
                    title: 'Actualizado Correctamente',
                    open: true,
                    variant: 'success'
                });
            } else {
                setAlert({
                    title: 'Hubo un problema inesperado',
                    open: true,
                    variant: 'error'
                })
            }
        })
    }

    return (
        <Select
            sx={{maxWidth: '90%'}}
            labelId={`role_${user._id}`}
            id={`role_${user._id}`}
            value={userRole}
            label="Role"
            onChange={sendRole}
            disabled = {userRole === 10}
            >
            {user.role==="Propietario" && <MenuItem value={10}>Propietario</MenuItem>}
            <MenuItem value={20}>Administrador</MenuItem>
            <MenuItem value={30}>Gestor de contenido</MenuItem>
            <MenuItem value={40}>Analista de contenido</MenuItem>
        </Select>
    )
}