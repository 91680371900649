import React, {useContext, useEffect, useState} from "react";
import {Box, FormControl} from "@mui/material";
import Grid from "@mui/material/Grid";
import {CustomTabPanel} from "./CustomTabPanel";
import TextField from "@mui/material/TextField";
import ButtonModal from "./ButtonModal";
import {customFlowApi} from "../../../../consumer/customFlowApi";
import CustomList from "./CustomList";
import Actions from "./Actions";
import {HomeContext} from "../../../context/HomeContext";
import DialogDelete from "../../../../common/DialogDelete";

const ListNewAgents = ({value, index}) => {
    const initialAgent = {
        usuario: "",
        email: "",
        accessTokenDesdeMeta: "",
        apikeyMetaWhatsapp: "",
        NumeroWhatsapp: ""
    }
    const {alert, setAlert, agents,setAgents } = useContext(HomeContext)
    const [isEditAgent, setIsEditAgent] = useState(false);
    const [open, setOpen] = useState(false);
    const [ modalTitle, setModalTitle ] = useState('Registrar Agente');
    const [ agent, setAgent ] = useState({ ...initialAgent })
    const [isLoading, setIsLoading ] = useState(true)
    const [ openDelete, setOpenDelete] =  useState(false);
    const [ selectAgent, setSelectAgent] =  useState(null);
    
    const handleChangeAgent = (event, key) => {
        setAgent(prevState => ({
            ...prevState,
            [key]: event.target.value
        }))
    }

    const getAgents = () => {
        setIsLoading(true);
        customFlowApi.getChatBots().then(response => {
            setIsLoading(false);
            if(response.status === 200){
                setAgents(response.data)
            }else{
                setAlert({...alert,
                    title: `Hubo un problema con la solicitud status: ${response.status}`,
                    open: true,
                    variant: 'error'
                });
            }
        })
    }
    const name = (chatbot) =>{
        return chatbot.usuario;
    }
    const validateAgentCreate = () => {
        const keysAgent = Object.keys(agent)
        const errors = []
        for (const agentKey of keysAgent) {
           if(agent[agentKey] === ''){
               errors.push(agentKey)
           }
        }
        return errors
    }
    const sendAgent = () => {
        if (isEditAgent){
            customFlowApi.updateChatBot(agent._id, agent).then(response => {
                console.log("agente actualizado", response)
                setAlert({...alert, title: 'Actualizado correctamente', open: true, variant: 'success'})
                setOpen(false)
                setIsEditAgent(false)
                getAgents()
            }).catch(e => {
                setAlert({...alert, title: e, open: true, variant: 'error'})
                setOpen(false)
                setIsEditAgent(false)
            })
        }else{
            if (!((validateAgentCreate()).length > 0)){
                customFlowApi.createChatBot(agent).then(_ => {
                    setAlert({...alert, title: 'Creado correctamente', open: true, variant: 'success'})
                    getAgents()
                }).catch(e => {
                    setAlert({...alert, title: e, open: true, variant: 'error'})
                    setOpen(false)
                    setIsEditAgent(false)
                })
            }else{
                setAlert({...alert, title: "Todos los campos son requeridos", open: true, variant: 'error'})
            }
        }
    }
    const editAgent = (agent) => {
        setIsEditAgent(true)
        setAgent({...agent })
        setModalTitle('Editar Agente')
        setOpen(true)
    }
    const deleteAgent = () => {
        customFlowApi.deleteChatBot(selectAgent._id).then(response => {
            setAlert({...alert, title: 'Eliminado correctamente', open: true, variant: 'success'})
            getAgents()
        }).catch(e => {
            setAlert({...alert, title: e, open: true, variant: 'error'})
            setOpen(false)
            setIsEditAgent(false)
        })
    }

    const cancel = () => {
        const reset = async () => {
            setIsEditAgent(false)
            setAgent({...initialAgent })
        }
        reset()
        setOpen(false)
    }

    useEffect(() => {
        getAgents()
    }, [value]);


    return (
        <CustomTabPanel value={value} index={index}>
            <Box flexGrow={1}>
                <Grid container spacing={1}>
                    <ButtonModal
                        open={open}
                        refresh={() => getAgents()}
                        setOpen={(open) => {
                            setModalTitle('Registrar Agente')
                            setAgent({
                                ...initialAgent
                            })
                            setOpen(open)
                        }}
                        onCancel={() => cancel()}
                        buttonText={'Nuevo Agente'}
                        modalTitle={modalTitle}
                        onClick={sendAgent}
                        fullWidth={false}>
                        <FormControl sx={{padding:4}}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <TextField
                                        value={agent.usuario}
                                        variant="standard"
                                        margin="normal"
                                        label="Usuario"
                                        size="small"
                                        onChange={(e)=>handleChangeAgent(e, 'usuario') }
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        value={agent.email}
                                        variant="standard"
                                        margin="normal"
                                        label="Correo"
                                        size="small"
                                        onChange={(e)=>handleChangeAgent(e, 'email') }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <TextField
                                        value={agent.NumeroWhatsapp}
                                        margin="normal"
                                        variant="standard"
                                        label="Número Whatsapp"
                                        size="small"
                                        onChange={(e)=>handleChangeAgent(e, 'NumeroWhatsapp') }
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        value={agent.apikeyMetaWhatsapp}
                                        margin="normal"
                                        variant="standard"
                                        label="Api Key Meta"
                                        size="small"
                                        onChange={(e)=>handleChangeAgent(e, 'apikeyMetaWhatsapp') }
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                value={agent.accessTokenDesdeMeta}
                                margin="normal"
                                variant="standard"
                                label="Token de Acceso Meta"
                                size="small"
                                fullWidth
                                onChange={(e)=>handleChangeAgent(e, 'accessTokenDesdeMeta') }
                            />
                        </FormControl>
                    </ButtonModal>

                    <Grid item xs={12} md={12}>
                        <CustomList items={agents} isLoading={isLoading} renderText={ name } actions={
                            (item) => <Actions
                                item={item}
                                deleteCallBack={(item) => {
                                    setSelectAgent(item)
                                    setOpenDelete(true)
                                }}
                                editCallback={(item) => editAgent(item)}
                            />
                        }/>
                    </Grid>
                </Grid>
                <DialogDelete openDialog={openDelete} setOpenDialog={setOpenDelete} handleDelete={deleteAgent}/>
            </Box>
        </CustomTabPanel>
    );
}

export default ListNewAgents