import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { HomeContext } from '../../context/HomeContext';
import Grid from "@mui/material/Grid";
import {WidgetSummary} from "./components/WidgetSummary";
import {customFlowApi} from "../../../consumer/customFlowApi";
export const DashboardView = () => {

  const [aveasData, setAveasData] = useState({});
  const {actualAgent, setAlert} = useContext(HomeContext)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
      (async () => {
          setIsLoading(true);
          const response = await customFlowApi.getAveasData();
          if(response.status === 200){
              setAveasData(response.data);
          }else{
              setAlert({
                  title: `Hubo un problema con la solicitud status: ${response.status}`,
                  open: true,
                  variant: 'error'
              });
          }
          setIsLoading(false);
      })();
  },[actualAgent])

  return (
    <Box sx={{ mt:14 }}>
        <Grid container rowSpacing={1} spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >
            <Grid item xs={2} sm={4} md={4}  >
                <WidgetSummary                   
                    boardIcon={"chat"}
                    //iconColor={"blue"}
                    isLoading={isLoading}
                    title="Chat Iniciados"
                    total={aveasData?.totalConversacionesIniciadas}
                />
            </Grid>
            <Grid item xs={2} sm={4} md={4} >
                <WidgetSummary
                    boardIcon={"shield"}
                    isLoading={isLoading}
                    title="Aceptación Política de datos"
                    total={aveasData?.totalAceptacionesPoliticaDatos}
                />
            </Grid>
            <Grid item xs={2} sm={4} md={4} >
                <WidgetSummary
                    boardIcon={"id_card"}
                    isLoading={isLoading}
                    title="Documentos de identidad ingresados"
                    total={aveasData?.totalCedulasIngresadas}
                />
            </Grid>
            <Grid item xs={2} sm={4} md={4} >
                <WidgetSummary
                    boardIcon={"person"}
                    isLoading={isLoading}
                    title="Nombre y apellidos de usuarios ingresados"
                    total={aveasData?.totalNombreIngresados}
                />
            </Grid>
            <Grid item xs={2} sm={4} md={4} >
                <WidgetSummary
                    boardIcon={"mail"}
                    isLoading={isLoading}
                    title="Correos ingresados"
                    total={aveasData?.totalEmailIngresados}
                />
            </Grid>
        </Grid>
    </Box>
  )
}
