import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


export function UserResult({ user}) {
  const { username, lastIngress } = user
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
    <Box
      sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
      }}
    >
        <Typography variant="h3" sx={{
        color: "#2596BE", px: 1.5,
        borderRadius: 4,
        mb: 2,
        }} >
        Control de acceso
        </Typography>
        <Grid pt={3} >
          <Grid item>
            <Typography variant="subtitle" fontWeight={'bold'} >
              Usuario:{" "}
            </Typography>
            <Typography variant="subtitle">
              {username}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle" fontWeight={'bold'} >
              Último ingreso:{" "}
            </Typography>
            <Typography variant="subtitle">
              {lastIngress}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}