import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { IconButton, Stack } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import UserList from './UserList';
import consumer from "../../../consumer/authModuleAPI";
import UpdatePassword from '../../components/UpdatePassword';
import UserCreate from './UserCreate';
import {useContext} from "react";
import {HomeContext} from "../../context/HomeContext";
import { customFlowApi } from '../../../consumer/customFlowApi';
import DialogDelete from '../../../common/DialogDelete';

export function AdminResult({ user }) {
    const { setAlert } = useContext(HomeContext);
    const { username, lastIngress } = user;
    const [users, setUsers] = React.useState([])
    const [ selectedUser, setSelectedUser] = React.useState({})
    const [ updatemodal, setUpdatemodal] = React.useState(false)
    const [ openDelete, setOpenDelete] =  React.useState(false);
    const [ isCreateVisible, setCreateVisible] = React.useState(false)

    React.useEffect(() => {
        refresh();
    // eslint-disable-next-line
    }, [])

    const refresh = () => {
        consumer.getUsers((res) => {
            if (res) {
                customFlowApi.getAllsUser().then((response)=>{
                    const _users = res.data.map(itemUser => {
                        let userFound = response.data.filter(_user => {
                            return _user.email === itemUser.username
                        })
                        if (userFound.length) {
                            return { ...itemUser, newId:userFound[0]._id }
                        }else{
                            return {...itemUser, newId:null }
                        }
                    });
                    setUsers(_users)
                })
                
            } else {
                setAlert({
                    title: 'Hubo un problema inesperado',
                    open: true,
                    variant: 'error'
                });
            }
        })
    }

    const handleDelete = () => {
        setOpenDelete(false);
        consumer.deleteUserFromAdmin(selectedUser,(response)=>{
            if (response) {
                setAlert({
                    title: 'Se elimino correctamente',
                    open: true,
                    variant: 'success'
                })
                refresh()
            }else {
                setAlert({
                    title: 'Hubo un problema inesperado',
                    open: true,
                    variant: 'error'
                });
            }      
        })
    };

    return (
        <Container component="main" maxWidth="lg">
            <UserCreate state={isCreateVisible} 
                setState={setCreateVisible} setDisable={true} refresh={refresh}/>
            <UpdatePassword user={selectedUser} state={updatemodal} 
                setState={setUpdatemodal} setDisable={false} />
            <DialogDelete openDialog={openDelete} setOpenDialog={setOpenDelete} 
                handleDelete={handleDelete}/>

            <Box
                sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h3" sx={{
                color: "#2596BE", px: 1.5,
                borderRadius: 4,
                mb: 2,
                }} >
                Control de acceso
                </Typography>
                <Stack direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2} mb={4}>
                    <Box item  >
                        <Typography variant="subtitle" fontWeight={'bold'} >
                            Usuario:{" "}
                        </Typography>
                        <Typography variant="subtitle">
                            {username}
                        </Typography>
                    </Box>
                    <Box item>
                        <Typography variant="subtitle" fontWeight={'bold'} >
                            Último ingreso:{" "}
                        </Typography>
                        <Typography variant="subtitle">
                            {lastIngress}
                        </Typography>
                    </Box>
                </Stack>

                <IconButton edge="start" aria-label="edit" sx={{ "&:hover": {
                        backgroundColor: "#ffffff !important",
                        boxShadow: "none !important",
                    },
                    "&:active": {
                        boxShadow: "none !important",
                        backgroundColor: "#ffffff !important",
                    }, mx: 1, color: '#2596be'}}
                    onClick={ () => {
                        setCreateVisible(true);
                    }
                }>
                    Nuevo usuario
                    <AddBoxIcon fontSize='large'/>
                </IconButton>

                {users && <UserList users={users.length > 0 ? users : []} selectUser={setSelectedUser} showModal={setUpdatemodal} refresh={refresh} setOpenDelete={setOpenDelete}/>}
            </Box>
        </Container>
    );
}