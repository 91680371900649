import * as React from 'react';
import { Button, Modal } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import consumer from "../../consumer/authModuleAPI";

export default function UpdatePassword({ state, setState, setDisable }) {
    const [ username, setUsername ] = React.useState(undefined)
    const [ isLoading, setIsLoading ] = React.useState(false)

    const onUsernameChange = (event) => {
        setUsername(event.target.value)
    }

    return (
        <>
        <Dialog open={state} >
            <DialogTitle>Solicitar correo de recuperación</DialogTitle>
            <DialogContent sx={{}}>
                <TextField
                    disabled={setDisable}
                    fullWidth
                    id="username"
                    label="Correo"
                    name="username"
                    value={username}
                    sx={{ my: 1.5 }}
                    onChange={onUsernameChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setState(false)
                }}>Cancelar</Button>
                <Button onClick={() => {
                    setState(false)
                    setIsLoading(true)
                    consumer.sendRecovery({
                        email: username,
                    }, (res) => {
                        setIsLoading(false)
                        if (res && res.sended) { 
                            alert("Correo de recuperación enviado con éxito. Revise su bandeja de entrada y spam")
                            setUsername("")
                        } else {
                            alert(res.msg)
                        }
                    })
                }}>Recuperar</Button>
            </DialogActions>
        </Dialog>
        <Modal open={isLoading} sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
        }}>
            <CircularProgress />
        </Modal>
    </>
    );
}