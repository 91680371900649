import * as React from 'react';
import { useContext } from "react"
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import UpdatePassword from '../../components/UpdatePassword';
import consumer from '../../../consumer/authModuleAPI';

export const ProfileView = () => {

    const { actualUser, setActualUser } = useContext(AuthContext);
    const [name, setName] = useState(actualUser.name);
    const [username, setUsername] = useState(actualUser.username);
    const [updatemodal, setUpdatemodal] = useState(false);
    const navigate = useNavigate();

    const logout = () => {
      setActualUser(null);
      localStorage.clear()
      alert("Logout successful");
      navigate('/login');
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      consumer.updateUser({
          ...actualUser,
          username,
          name,
      }, (res) => {
          if (res) {
              setActualUser({
                  ...actualUser,
                  username,
                  name,
              })
              alert("Datos guardados exitosamente")
          } else {
              alert("Error de conexión");
          }
      })
  };

    return (
      <Container component="main" sx={{width:'100%', display:'flex', flexDirection:'column', justifyContent: 'center',
      alignItems: 'center'}} >
        <UpdatePassword user={actualUser} state={updatemodal} 
          setState={setUpdatemodal} setDisable={true} />
        <CssBaseline />
      
        <Typography variant="h3" sx={{
        color: "#2596BE", px: 1.5,
        borderRadius: 4,
        mt: 4,
        mb: 2,
        width:'100%',
        textAlign:'center'
        }} >
        Configuración de la cuenta
        </Typography>
       
          <Box
            sx={{
                bgcolor: "#fff",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 5,
                width:{xs:'80%', md:'40%'},
                }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    id="name"
                    label="Nombre completo"
                    name="name"
                    autoComplete="name"
                    value={name}
                    onChange={(e)=>setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    name="email"
                    label="Correo"
                    type="email"
                    id="email"
                    autoComplete="email"
                    value={username}
                    onChange={(e)=>setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                  <TextField
                      required
                      fullWidth
                      name="tipo"
                      type="tipo"
                      id="tipo"
                      disabled={true}
                      value={actualUser.role}
                  />
              </Grid>
            </Grid>
            <Grid item xs>
              <Button variant="text"
                    sx={{ fontWeight: "bold", textTransform: 'initial', alignSelf: "end", pt: 2 }}
              onClick={()=>setUpdatemodal(true)}>
                Cambiar contraseña
              </Button>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: "#fff", fontSize: 18, fontWeight: "bold", bgcolor: "#00B84F", textTransform: "capitalize" }}
                disableElevation
                onClick={handleSubmit}
                >
                  Guardar
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                variant="outlined"
                fullWidth
                sx={{ mt: 3, mb: 2, fontSize: 16, fontWeight: "bold", textTransform: "initial" }}
                onClick={()=>navigate('/')}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Button
                type="submit"
                fullWidth
                variant="outlined"
                sx={{ mt: 3, mb: 2, fontSize: 16, fontWeight: "bold", textTransform: "initial" }}
                disableElevation
                onClick={logout}
                >
                  Cerrar sesión
                </Button>
              </Grid>
              <Grid item xs>
                {
                  (actualUser.role!=='Propietario') &&
                  <Button
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 3, mb: 2, fontSize: 16, color: 'red', borderColor: 'red', fontWeight: "bold", textTransform: "initial" }}
                  onClick={ () => {
                    }
                  }>
                    Eliminar cuenta
                  </Button>
                }
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    );
}