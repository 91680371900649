import Box from "@mui/material/Box";
import {
    DataGrid,
    GridToolbar,
} from "@mui/x-data-grid";
import {esES} from "@mui/x-data-grid/locales";
import LinearProgress from "@mui/material/LinearProgress";
import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import {IconButton, Stack} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const IntentsTable = (
    { loading, intents, deleteCallBack, editCallback, paginationModel, setPaginationModel, setRowSelectionModel, rowSelectionModel, rowCount}) => {

    const intentionTypes = {
        'button': 'Boton',
        'list': 'Lista',
        'text': 'Texto'
    };

    const  data = {
        columns: [
            { field: 'type', headerName: 'Tipo', flex: 1,
                renderCell: (row) => {
                    return intentionTypes[row.row.type];
                }
            },
            { field: 'blockname', headerName: 'Intención', flex: 1 },
            {
                field: 'action',
                headerName: 'Acción',
                flex:1,
                sortable: false,
                disableClickEventBubbling: true,
                renderCell: (row) => {
                    return (
                        <Stack direction='row'>
                            <Tooltip title="Editar" placement="right">
                                <IconButton onClick={()=> editCallback(row.row)}
                                            aria-label="edit" color="info" size="small">
                                    <EditIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar" placement="right">
                                <IconButton onClick={()=> deleteCallBack(row.row)}
                                            aria-label="delete" color="error" size="small">
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    )
                }
            }
        ],
        rows: intents,
    };


    return (
        <Box sx={{height: 550, width: "100%"}}>
            <DataGrid
                density={"compact"}
                loading={loading}
                pagination
                paginationModel={paginationModel}
                pageSizeOptions={[10]}
                rowCount={rowCount}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                getRowId={(row) => row._id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                {...data}
                slotProps={{
                    toolbar: {
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                    }}}
                slots={{
                    toolbar: GridToolbar, loadingOverlay: LinearProgress, }}/>
        </Box>
    );
}

export default IntentsTable;