import { Box, Button, Typography } from '@mui/material'
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';


export const AccountButton = () => {

  const {actualUser:{username}, setActualUser} = useContext(AuthContext)
  const navigate = useNavigate()

  const handleProfile = (e) => {
    e.preventDefault();
    navigate('/perfil');
  };
  
  return (
    <Box
        sx={{
            display: { xs: 'flex', md: 'flex' }, flexDirection: 'row', alignItems:'center',
            border: 1,
            borderRadius: 2,
            padding: 0.5,
            borderColor: '#00B84F',
            margin: { xs: 2, md: 2 },
            justifyContent: 'center'
        }}
    >
      <Box display='flex' justifyContent='center' alignItems='center' >
        <Link component="button" onClick={handleProfile} to={''} >
          <PersonIcon />
        </Link>
      </Box>
      
      <Box sx={{display:{ xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems:'center', }}>
        <Link component="button" onClick={handleProfile} to={''} >
          <Typography variant="body" color={'#00B84F'} fontWeight={'bold'} textTransform={'initial'} sx={{mr: 2}}>{username}</Typography>
        </Link>
      </Box>
      
        <Button sx={{color:'#00B84F', bgcolor:'#ffffff'}}  onClick={ ()=>{
          setActualUser(null)
          navigate('/login')
        }}>
          <LogoutIcon />
        </Button>
    </Box>
  )
}
