import "./App.css";
import * as React from "react";
import { BrowserRouter } from 'react-router-dom'
import {  ThemeProvider } from '@mui/material/styles';
import theme  from "./theme/theme.jsx"
import { AppRouter } from "./router/AppRouter";
import { AuthProvider } from "./context/AuthProvider";


function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <AppRouter/>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
