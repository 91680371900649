import React from 'react'
import {AdminResult, UserResult} from '../access';
import { AuthContext } from '../../../context/AuthContext';

export const AccessView = () => {
  const {actualUser, actualUser:{role}} = React.useContext(AuthContext)

  const isAdministrador = role==='Propietario' || role==='Administrador';

  return (
    <>
      { isAdministrador &&
        <AdminResult user={actualUser} />
      }
      { !isAdministrador &&
        <UserResult user={actualUser}/>
      }
    </>
  )
}
