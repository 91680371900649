import {useMemo, useState} from "react"
import { HomeContext } from "./HomeContext"

const init = () => {
    const agent = localStorage.getItem('agent')

    if(agent !== null){
        return JSON.parse(agent)
    }else{
        return {index:'default'};
    }
}

export const HomeProvider = ({ children }) => {
    const [ agents, setAgents_ ] = useState([])
    const [actualAgent, setRealActualAgent] = useState(init())
    const [alert, setAlert] = useState({
        title: '',
        open: false,
        variant: 'success'
    })

    const setActualAgent = (agent) => {
        setRealActualAgent(agent);
        localStorage.setItem('agent', JSON.stringify(agent))
    }

    const setAgents = (newAgents) => {
        const agent = newAgents[actualAgent.index];
        setRealActualAgent({
            index: actualAgent.index,
            agent: agent
        })
        setAgents_(newAgents);
    }

    const homeContext = useMemo(
        () => {
            return { actualAgent, setActualAgent, alert, setAlert, agents, setAgents }
        },
        [ actualAgent, alert, agents ]
    );

    return (
        <HomeContext.Provider value={homeContext}>
            {children}
        </HomeContext.Provider>
    )
}
