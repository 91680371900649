import { customFlowApi } from "./customFlowApi"

const host = process.env.REACT_APP_BACKEND_HOST || 'https://host.abrahamchatbot.com'
const port = process.env.REACT_APP_AUTH_PORT || null
const prefix = process.env.REACT_APP_PREFIX_AUTH || '/api'
const baseUrl = `${host}${port ? `:${port}` : ''}${prefix.trim()}`
const urlWhatsapp = process.env.REACT_APP_BACKEND_WHATSAPP
const usersUrl = `${baseUrl}/users`
const token = localStorage.getItem('token') ? localStorage.getItem('token') : ''

const consumer = {
  getUsers: (response) => {
    fetch(usersUrl, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => response.json())
      .then((data) => response(data));
  },

  getUser: (id, response) => {
    fetch(`${usersUrl}/${id}`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => response.json())
      .then((data) => {
        customFlowApi.getAllsUser().then((_res)=>{
          const _users = _res.data.filter((_user)=>{
            return _user.email == data.data.username
          })
          if (_users.length) {
            response({data:{... data.data, newId: _users[0]._id}})  
          }else{
            response({data:{... data.data, newId: null}})
          }
        })
      }); 
  },

  deleteUser: (id, response) => {
    fetch(`${usersUrl}/${id}`, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => response.json())
    .then((data) => response(data));
  },

  deleteUserFromAdmin: (user, response) => {
    fetch(`${usersUrl}/${user._id}`, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then((response) => response.json())
      .then((data) => {
          if (user.newId) {
            customFlowApi.deleteUser(user.newId)
              .then(dataWhatsapp => {
                console.log(dataWhatsapp)
                response(data)
            });            
          }else{
            response(data)
          }      
      });
  },

  createUser: (user, response) => {
    fetch(usersUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(user) // body data type must match "Content-Type" header
      }).then((response) => response.json())
      .then((data) => {
        localStorage.setItem('token', data.token)
        response(data)
      });
  },

  createUserFromAdmin: (user, response) => {
    fetch(usersUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(user) // body data type must match "Content-Type" header
      }).then((response) => response.json())
      .then((data) => {
        customFlowApi.createUser({email: user.username, password: user.password})
          .then(dataWhatsapp => {
              console.log(dataWhatsapp)
              response(data)
          });
      });
  },

  updateUserFromAdmin: (user, response) => {
    fetch(usersUrl, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(user) // body data type must match "Content-Type" header
      }).then((response) => response.json())
      .then((data) => {
        if (user.newId) {
          customFlowApi.updateUser(user.newId, {email: user.username, password: user.password})
            .then(dataWhatsapp => {
              console.log(dataWhatsapp)
              response(data)
          });
        }else{
          response(data)
        }
      });
  },

  updateUser: (user, response) => {
    fetch(usersUrl, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(user) // body data type must match "Content-Type" header
      }).then((response) => response.json())
      .then((data) => {
        response(data)
      });
  },

  authenticateUser: (credentials, response) => {
    fetch(`${usersUrl}/auth`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(credentials) 
    }).then((response) => response.json().then( data => ({ data, status: response.status }) ))
    .then(data => {
        fetch(`${urlWhatsapp}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: credentials.username, password: credentials.password})
        }).then(response => response.json().then( (data) => ({ data, status: response.status }) )
        ).then(dataWhatsapp => {
            response({...data, dataWhatsApp:dataWhatsapp})
        });
    });
  },

  sendSettings: (settings, response) => {
    fetch(`${baseUrl}/settings`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(settings) // body data type must match "Content-Type" header
      }).then((response) => response.json())
      .then((data) => response(data));
  },

  getSettings: (response) => {
    fetch(`${baseUrl}/settings`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => response.json())
      .then((data) => response(data));
  },

  sendRecovery: (email, response) => {
    fetch(`${baseUrl}/forgotten`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(email) // body data type must match "Content-Type" header
      }).then((response) => response.json())
      .then((data) => response(data));
  },

  getAgents: (response) => {
      console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", `${baseUrl}/agents`)
    fetch(`${baseUrl}/agents`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => response.json())
      .then((data) => response(data));
  },

  validateToken: (token, response)=>{
    fetch(`${baseUrl}/validate?token=${token}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then((response) => response.json())
    .then((data) => {
      localStorage.setItem('token', data.accessToken)
      console.log(data)
      response(data)
    });
  }

};

export default consumer;
