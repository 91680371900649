import { Route, Routes } from "react-router-dom"
import { HomeRoutes } from "../home/routes/HomeRoutes"
import Auth  from "../auth/pages/Auth"
import { PrivateRoutes} from "./PrivateRoutes"
import { PublicRoutes } from "./PublicRoutes"

export const AppRouter = () => {
  return (
    <Routes>
        <Route path="/login" element={
          <PublicRoutes>
            <Auth/>
          </PublicRoutes>
        }/>

        <Route path="/forgotten/:token" element={
          <PublicRoutes>
            <Auth/>
          </PublicRoutes>
        }/>

        <Route path="/*" element={
          <PrivateRoutes>
            <HomeRoutes/>
          </PrivateRoutes>
        }/>
    </Routes>
  )
}