import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

export default function DialogDelete({openDialog, setOpenDialog, handleDelete}) {
 
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <BootstrapDialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          ¿Estás seguro?
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography >
           Que quiere eliminar este usuario definitivamente
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color='error' autoFocus onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="success" onClick={handleDelete}>
            Aceptar
          </Button>
        </DialogActions>

      </BootstrapDialog>
  );
}