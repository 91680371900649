import { BotSelector } from './BotSelector'
import { AccountButton } from './AccountButton'
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import * as React from "react";
import { DRAWERWITH } from "../constants";
import {Box} from "@mui/material";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, margin }) => ({
    backgroundColor: '#FFF',
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${DRAWERWITH}px)`,
        marginLeft: margin,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const ToolBar = ({ handleDrawerOpen, open }) => {
    return (
        <AppBar position="fixed" open={open}
                margin={open ? `${DRAWERWITH}px`: 0}
                elevation={0}
                sx={{
                    borderBottom: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: '#2596BE',
                    width: { sm: `calc(100% - ${DRAWERWITH}px)` },
                    ml: { sm: `${DRAWERWITH}px` }
                }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, display: { sm: 'none' } }}>
                    <MenuIcon />
                </IconButton>
                <Box sx={{flexGrow:1, display: 'flex', justifyContent: 'end'}}>
                    <BotSelector/>
                    <AccountButton/>
                </Box>
            </Toolbar>
        </AppBar>
    );
}