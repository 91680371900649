import { ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React from "react";

const SidebarButton = ({ onClick, icon, text }) => {
    const centerStyle = {
        flexDirection: 'column', // Coloca los elementos en una columna
        alignItems: 'center', // Centra los elementos horizontalmente
        justifyContent: 'center', // Centra los elementos verticalmente,
        color:'#FFF'

    };
    return (
        <ListItem key={text} disablePadding >
            <ListItemButton sx={centerStyle} onClick={onClick} >
                <ListItemIcon sx={centerStyle}>
                    {icon}
                </ListItemIcon>
                <ListItemText disableTypography={true} sx={{textAlign:'center', fontSize: '0.7rem'}} primary={text} />
            </ListItemButton>
        </ListItem>
    );
}

export default SidebarButton