import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { es } from "date-fns/locale";
registerLocale("es", es);
const Calendar = (props) => {
    return (
        <DatePicker
            locale={'es'}
            showMonthDropdown
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            {...props}
        />
    );
};

export default Calendar