import { createTheme } from '@mui/material/styles';
import {esES as coreBgBG} from '@mui/material/locale';

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#00B84F',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: '#0066ff',
      main: '#2596BE',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fffff',
    },
  },
  typography: {
    fontFamily: [
      'Quicksand',
      'Roboto',
      'sans-serif'
    ].join(','),
  },
  coreBgBG, // core translations
});

export default theme