import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import consumer from "../../consumer/authModuleAPI";
import { CircularProgress, Divider, FormControl, IconButton, InputAdornment, InputLabel, Modal, OutlinedInput } from '@mui/material';
import { useState } from 'react';
import Copyright from "../../common/Copyright";
import BoxForm from "./BoxForm";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


export default function SignIn({ home, handleSign, handleForgot, handleSignUp, disableSign, handleAlert}) {
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const gateDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yy = today.getFullYear();
    return `${mm}/${dd}/${yy}`;
}

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true)
    const data = new FormData(event.currentTarget);
    consumer.authenticateUser({
      username: data.get('email'),
      password: data.get('password')
    }, (res) => {
        if(res.status === 200){
          localStorage.setItem('dataWhatsApp',JSON.stringify(res.dataWhatsApp));
          localStorage.setItem('token',res.data.token);
          consumer.updateUser({
            ...res.data.data,
            lastIngress: gateDate()
          }, res.data.token, (res) => {})
          handleSign(res.data.data);
        } else {
          handleAlert(res.data.error, 'error')
        }
      setIsLoading(false)
    })
  };

  return (
    <>
      <BoxForm onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo"
                name="email"
                autoComplete="email"
                autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Contraseña"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 2, color: "#fff", fontSize: 18, fontWeight: "bold", bgcolor: "#00B84F", textTransform: "capitalize" }}
            disableElevation>
          Ingresar
        </Button>
        <Grid container>
          <Grid item xs={12}>
            <Button
                fullWidth
                variant="outlined"
                sx={{ fontSize: 16, fontWeight: "bold", textTransform: "initial" }}
                onClick={ () => {
                  handleForgot(true)
                }}>
              ¿Olvidaste tu contraseña?
            </Button>
          </Grid>
        </Grid>
        <Divider/>
        <Grid container>
          {/* <Grid item xs={12}>
            <Typography variant="body1" color={"#2596BE"} textAlign={'center'} ml={8} fontSize={14} mt={1}>
              ¿No tienes aún cuenta?
            </Typography>
          </Grid> */}

            { !disableSign && (
                <Grid item xs={12}>
                  <Button variant="text"
                          sx={{ fontWeight: "bold", textTransform: 'initial', alignSelf: "end", pt: 2 }}
                          onClick={ () => {
                            handleSignUp("sigup")
                          }}>Regístrate</Button>
                </Grid>
            )}
        </Grid>
        <Divider/>
        <Grid container >
          <Grid item xs={12}>
            <Button sx={{ fontWeight: "bold", textTransform: 'initial', alignSelf: "start" }} variant="text" onClick={() => home()}>Volver</Button>
          </Grid>
        </Grid>
      </BoxForm>
      <Copyright sx={{ mt: 1}} />
      <Modal open={isLoading} sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
      }}>
        <CircularProgress />
      </Modal>
    </>
  );
}