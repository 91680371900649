import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";

export default function Copyright(props) {
    return (
        <Typography variant="body2" color={"#FFF"} align="center" {...props}>
            {'Copyright © '}
            <Link color={"#FFF"} href="https://mui.com/">
                Abraham Chatbot
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}